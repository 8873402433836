<template>
  <div>
    <div class="d-flex flex-row justify-space-between align-center">
      <div class="d-flex flex-row justify-start align-center">
        <div class="px-5">
          <v-img
            src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
            contain
            :height="96"
            :width="160"
          />
        </div>
        <span class="px-5 text-h5 font-weight-bold"> Procore Rewards </span>
      </div>
      <v-btn
        @click="openPlatform"
        outlined
        depressed
        color="brandCyan"
        class="mr-5"
      >
        <span class=" brandCyan--text pt-1">Go to Whistle</span>
        <v-icon right color="brandCyan">
          mdi-arrow-right-bold-circle-outline
        </v-icon>
      </v-btn>
    </div>
    <v-row justify="space-between" class="px-2">
      <v-col v-if="true" lg="3" cols="12">
        <v-card
          outlined
          elevation="1"
          flat
          class="full-height elevation-1 pa-2"
          style="min-height: 70vh"
        >
          <marqeta-widget-manager-v-2
            class="pa-1"
            ref="marqeta-widget"
            :selected-budget="Budget"
          />
        </v-card>
      </v-col>
      <v-col :lg="IsAdmin ? 6 : 9" cols="12">
        <v-card outlined flat class="elevation-1" style="min-height: 70vh">
          <v-container class="white-table-headers">
            <v-data-table
              id="table"
              :headers="Headers"
              :items="Programs"
              :loading="display.loading.programs"
              :items-per-page="10"
              :show-expand="IsAdmin"
              :expanded="expanded"
              item-key="programId"
              @click:row="programRowClick"
              @item-expanded="programRowClick"
            >
              <template v-slot:item.project="{ item }">
                <div class="word-break">
                  {{ item | programSupplementalProjectName }}
                </div>
              </template>
              <template v-slot:item.program="{ item }">
                <div class="word-break">
                  {{
                    userProfile?.businessEmail?.includes("@wewhistle.com")
                      ? item.programId + " - "
                      : ""
                  }}{{
                    item?.ProgramElements?.[0]?.MetricDefinition?.definition1
                  }}
                </div>
              </template>
              <template v-slot:item.metric="{ item }">
                <div
                  class="d-flex flex-column"
                  v-if="item.status === 'Active' || item.status === 'Scheduled'"
                >
                  <span
                    v-if="item.ProgramElements && item.ProgramElements.length"
                    >{{
                      item.ProgramElements[0].MetricDefinition.definition1
                    }}</span
                  >
                  <span
                    class="grey--text text--darken-2"
                    v-if="
                      item.ProgramElements[0].MetricDefinition.definition1 !==
                        'Submittals' && item.ruleAggregation !== 'ONE_TIME'
                    "
                    >Goal: {{ item.ProgramElements[0].value }}
                    {{ item | payoutFrequency }}</span
                  ><span v-else class="grey--text text--darken-2">
                    Goal: {{ item.ProgramElements[0].value }}% completion
                  </span>
                </div>
                <span class="body-1 font-italic" v-else>
                  This program is being configured by a Whistle CSM.
                </span>
              </template>
              <template v-slot:item.reward="{ item }">
                <span class="success--text">{{
                  {
                    amount: item.maxProgramCostPerUser,
                    type:
                      (item.Budget &&
                        item.Budget.Award &&
                        item.Budget.Award.awardName) ||
                      "Cash"
                  } | award
                }}</span>
                <br />
                <span>per participant</span>
                <!-- <div
                  class="d-flex flex-column"
                  v-if="
                    item.payoutAmount &&
                      (item.status === 'Active' || item.status === 'Scheduled')
                  "
                >
                  <span class="success--text">{{
                    {
                      amount: item.payoutAmount,
                      type:
                        (item.Budget &&
                          item.Budget.Award &&
                          item.Budget.Award.awardName) ||
                        "Cash"
                    } | award
                  }}</span>
                  <span
                    class="grey--text text--darken-2"
                    v-if="
                      item.ProgramElements[0].MetricDefinition.definition1 !==
                        'Submittals' && item.ruleAggregation !== 'ONE_TIME'
                    "
                    >({{ item | payoutFrequency }})</span
                  >
                </div> -->
              </template>
              <template
                v-slot:[`item.data-table-expand`]="{
                  item,
                  isExpanded,
                  expand
                }"
              >
                <v-btn @click.stop="expand(!isExpanded)" icon
                  ><v-icon large>{{
                    isExpanded ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon></v-btn
                >
              </template>
              <template v-slot:expanded-item="{ item, headers }">
                <td
                  id="table-inner"
                  :colspan="headers.length"
                  class="py-4"
                  :class="
                    $vuetify.breakpoint.sm || $vuetify.breakpoint.xs
                      ? 'px-3'
                      : 'px-6'
                  "
                  v-if="item"
                >
                  <v-row class="text-left"
                    ><v-col cols="12" sm="12" md="6" lg="6" xl="6">
                      <div class="d-flex align-center">
                        <p class="font-weight-bold body-1 text-left my-1">
                          Participants:
                        </p>
                        <v-btn
                          v-if="item.status !== 'Archived' && item.projectId"
                          small
                          icon
                          @click="editProgram('editAudience', item)"
                          ><v-icon small>mdi-pencil</v-icon></v-btn
                        >
                      </div>
                      <p class="my-0">
                        <v-icon>mdi-account-multiple</v-icon>
                        <v-progress-circular
                          v-if="
                            data.programAudienceCountLoading[item.programId] ===
                              true
                          "
                          indeterminate
                          color="brandCyan"
                          :size="20"
                          :width="2"
                          class="ml-2"
                        ></v-progress-circular>
                        <span v-else>
                          {{ data.programAudienceCount[item.programId] }}</span
                        >
                      </p>
                      <p class="my-2">
                        <v-icon>{{
                          item.userLevelAggregation
                            ? "mdi-account"
                            : "mdi-account-multiple"
                        }}</v-icon>
                        <span class="ml-1">{{
                          item.userLevelAggregation
                            ? "Individual program"
                            : "Team program"
                        }}</span>
                        <v-tooltip top
                          ><template v-slot:activator="{ on, attrs }"
                            ><v-icon v-on="on" v-bind="attrs" class="ml-2"
                              >mdi-help-circle-outline</v-icon
                            ></template
                          ><span v-if="item.userLevelAggregation"
                            >Progress is tracked for each individual and each
                            participant is only paid out when they have
                            satisfied all the requirements themselves.</span
                          ><span v-else
                            >Progress is tracked for the project and anyone in
                            the program is paid out when the goal is reached,
                            regardless of who completed the requirements.
                          </span></v-tooltip
                        >
                      </p>
                      <div class="d-flex align-center mt-5 mb-1">
                        <p class="font-weight-bold body-1 text-left mb-0">
                          Timeframe:
                        </p>
                        <v-btn
                          v-if="item.status !== 'Archived'"
                          small
                          icon
                          @click="editProgram('editSchedule', item)"
                          ><v-icon small>mdi-pencil</v-icon></v-btn
                        >
                      </div>
                      <!-- <div class="d-flex justify-space-between mt-2 py-1"> -->
                      <p class="my-0" v-show="item.startDate">
                        Start date:
                        {{
                          { date: item.startDate, timezone: item.timezone }
                            | formattedDate
                        }}
                      </p>
                      <p class="my-0" v-if="item.endDate">
                        End date:
                        {{
                          { date: item.endDate, timezone: item.timezone }
                            | formattedDate
                        }}
                      </p>
                      <p class="my-0" v-if="item.timezone">
                        Timezone: {{ item.timezone }}
                      </p>
                      <!-- </div> -->
                      <div class="d-flex align-center mt-5 mb-1">
                        <p class="font-weight-bold body-1 text-left mb-0">
                          Rewards:
                        </p>
                        <v-btn
                          v-if="item.status !== 'Archived'"
                          small
                          icon
                          @click="editProgram('editReward', item)"
                          ><v-icon small>mdi-pencil</v-icon></v-btn
                        >
                      </div>
                      <p class="my-0">
                        Base reward:
                        <span class="font-weight-bold">
                          {{
                            {
                              amount: item.payoutAmount,
                              type: item?.Budget?.Award?.awardName || "Cash"
                            } | award
                          }}</span
                        >
                      </p>
                      <p class="my-0" v-if="item.ongoingInitialPayoutAmount">
                        Initial bonus:
                        <span class="font-weight-bold">
                          {{
                            {
                              amount: item.ongoingInitialPayoutAmount,
                              type: item?.Budget?.Award?.awardName || "Cash"
                            } | award
                          }}</span
                        >
                      </p>
                      <p class="my-0" v-if="item.ongoingStreakPayoutAmount">
                        Streak bonus:
                        <span class="font-weight-bold">
                          {{
                            {
                              amount: item.ongoingStreakPayoutAmount,
                              type: item?.Budget?.Award?.awardName || "Cash"
                            } | award
                          }}</span
                        >
                      </p>
                      <p class="my-0" v-if="item.Budget">
                        Budget:
                        <span class="font-weight-bold">
                          {{ item?.Budget?.budgetDisplayName
                          }}<span
                            v-if="
                              item?.budgetId &&
                                item?.Budget?.balance !== undefined
                            "
                          >
                            -
                            {{
                              {
                                amount: item?.Budget?.balance,
                                type: item?.Budget?.Award?.awardName || "Cash"
                              } | award
                            }}</span
                          ></span
                        >
                      </p>
                      <!-- <p class="my-0" v-if="item.payoutMemo">
                        Reward payout memo:
                        <span v-html="item.payoutMemo"> </span>
                      </p> --> </v-col
                    ><v-col cols="12" sm="12" md="6" lg="6" xl="6">
                      <div
                        v-if="
                          item.ProgramElements && item.ProgramElements.length
                        "
                        class="ml-5 word-break"
                      >
                        <p class="font-weight-bold body-1 mb-1">
                          Program requirements:
                        </p>
                        <div
                          v-for="resource in item.ProgramElements"
                          :key="resource.programElementId"
                        >
                          <span
                            class=""
                            v-if="
                              resource?.MetricDefinition?.definition1 !==
                                'Submittals' &&
                                item.ruleAggregation !== 'ONE_TIME'
                            "
                            >{{
                              getFriendlyResourceName(
                                resource?.MetricDefinition?.definition1,
                                true
                              )
                            }}: {{ resource.value }}
                            <span
                              v-if="item?.config?.resource !== 'Daily Logs'"
                              >{{ item | payoutFrequency }}</span
                            > </span
                          ><span v-else class="">
                            Submittal Goal: {{ resource.value }}% completion
                          </span>
                        </div>
                      </div>
                      <div v-if="item.status !== 'Archived'">
                        <v-btn
                          text
                          class="mt-5"
                          @click="updateProgram([], item.programId)"
                          ><v-icon>mdi-archive-arrow-down</v-icon>Archive
                          program</v-btn
                        >
                        <p
                          v-if="misc.editingErrorMessage"
                          class="error--text ml-5 word-break"
                        >
                          {{ misc.editingErrorMessage }}
                        </p>
                      </div>
                    </v-col></v-row
                  >
                </td>
              </template>
            </v-data-table>
            <v-navigation-drawer
              v-model="display.configuration"
              temporary
              fixed
              right
              width="500"
              disable-route-watcher
            >
              <template v-if="!display.fundingBudget">
                <v-toolbar
                  dark
                  color="deep-orange lighten-1"
                  rounded="0"
                  elevation="0"
                >
                  <v-toolbar-title>{{ ConfigurationTitle }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn depressed dark text @click="stopConfiguring">
                      <v-icon class="">mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <template v-if="IsEditingProgram">
                  <div class="mx-6 mt-6 mb-4">
                    <v-form ref="form" lazy-validation>
                      <ProcoreRewardEditor
                        v-if="display.editReward"
                        :IsSubmittal="IsSubmittal"
                        :isDailyLog="input.resource === 'Daily Logs'"
                        :IsCashBudget="IsCashBudget"
                        :reward="input.reward"
                        :rewardRules="validation.reward"
                        :streakBonus="input.ongoingStreakPayoutAmount"
                        :initialBonus="input.ongoingInitialPayoutAmount"
                        :bonusRules="validation.bonus"
                        :budgetId="input.rewardBudget"
                        :budgetRules="validation.budget"
                        :Budgets="BudgetOptions"
                        @update-reward="input.reward = $event"
                        @update-streak="
                          input.ongoingStreakPayoutAmount = $event
                        "
                        @update-initial="
                          input.ongoingInitialPayoutAmount = $event
                        "
                        @update-budget="input.rewardBudget = $event"
                      />
                      <ProcoreScheduleEditor
                        v-else-if="display.editSchedule"
                        :startDate="input.startDate"
                        :endDate="input.endDate"
                        :endNow="input.endNow"
                        :timezone="input.timezone"
                        :HasStarted="input.programHasStarted"
                        :HasEnded="input.programHasEnded"
                        :dateErrorMessage="HasInvalidDatesWhileEditing"
                        :timezones="fixtures.timezones"
                        @update-start-date="input.startDate = $event"
                        @update-end-date="input.endDate = $event"
                        @update-end-now="input.endNow = $event"
                        @update-timezone="input.timezone = $event"
                      />
                      <ProcoreAudienceEditor
                        v-else-if="display.editAudience"
                        :groups="input.groups"
                        :projectId="input.project"
                        :loading="display.loading.registration"
                        :CompanyIdParams="CompanyIdParams"
                        :errorMessage="misc.editingErrorMessage"
                        @handle-error="errors.push($event)"
                        @submit="updateProgram"
                      />
                    </v-form>
                    <div class="mt-6" v-if="!display.editAudience">
                      <v-btn
                        color="brandCyan"
                        class="white--text"
                        rounded
                        depressed
                        @click="updateProgram"
                        :loading="display.loading.registration"
                        :disabled="!ProgramEditSaveReady"
                        >Save</v-btn
                      >
                      <p
                        class="error--text mt-2"
                        v-if="misc.editingErrorMessage"
                      >
                        {{ misc.editingErrorMessage }}
                      </p>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="d-flex justify-center align-center my-5">
                    <pill-nav
                      :steps="steps"
                      width="20rem"
                      :height="30"
                      :step="display.configStep"
                      @input="nextStep"
                      color="brandCyan"
                    >
                    </pill-nav>
                    <v-btn
                      rounded
                      color="brandCyan"
                      depressed
                      :dark="!!NextStepReady"
                      :disabled="!NextStepReady"
                      :height="30"
                      @click="
                        v =>
                          display.configStep !== 5
                            ? nextStep(v)
                            : stopConfiguring()
                      "
                    >
                      <template v-if="display.configStep !== 5">
                        <v-icon> mdi-arrow-right-circle </v-icon>
                        Next
                      </template>
                      <template v-else>
                        <v-icon>mdi-check-circle</v-icon>
                        Done
                      </template>
                    </v-btn>
                  </div>
                  <v-stepper
                    v-if="IsAdmin"
                    class="flat"
                    v-model="display.configStep"
                  >
                    <v-stepper-items>
                      <v-stepper-content step="1">
                        <div v-if="!StepOneLoading">
                          <v-form ref="form" lazy-validation>
                            <div class="d-flex flex-column">
                              <template>
                                <div class="d-flex justify-start pb-3">
                                  <label class="text-h6">
                                    Choose a company:
                                  </label>
                                </div>
                                <div class="d-flex justify-space-between mb-7">
                                  <v-autocomplete
                                    :loading="display.loading.companies"
                                    :disabled="
                                      display.loading.people || !MultiCompany
                                    "
                                    outlined
                                    dense
                                    hide-details
                                    v-model="input.company"
                                    :rules="validation.company"
                                    placeholder="Select a company"
                                    item-text="name"
                                    item-value="id"
                                    color="brandCyan"
                                    :items="Companies"
                                    :menu-props="{ offsetY: true }"
                                    :no-data-text="
                                      display.loading.companies
                                        ? 'Loading companies...'
                                        : 'No companies available'
                                    "
                                  >
                                    <template v-slot:append-outer>
                                      <v-btn
                                        icon
                                        class="mt-n2"
                                        :disabled="display.loading.companies"
                                        @click="getCompanies(true)"
                                        ><v-icon>mdi-refresh</v-icon></v-btn
                                      >
                                    </template>
                                  </v-autocomplete>
                                </div>
                              </template>
                              <template>
                                <div class="d-flex justify-start">
                                  <label class="text-h6">
                                    Select your project
                                  </label>
                                </div>
                                <div
                                  class="d-flex flex-column justify-space-between"
                                >
                                  <div
                                    class="d-flex align-self-end align-center"
                                  >
                                    <label for="showInactive" class="px-3">
                                      Show inactive projects:
                                    </label>
                                    <v-checkbox
                                      size="small"
                                      id="showInactive"
                                      color="brandCyan"
                                      v-model="display.showInactiveProjects"
                                    ></v-checkbox>
                                  </div>
                                  <v-autocomplete
                                    :loading="
                                      display.loading.projects ||
                                        display.loading.companies
                                    "
                                    :disabled="
                                      display.loading.projects ||
                                        display.loading.companies ||
                                        display.loading.people
                                    "
                                    outlined
                                    dense
                                    v-model="input.project"
                                    :rules="validation.project"
                                    placeholder="Select a project"
                                    item-text="desc"
                                    item-value="id"
                                    color="brandCyan"
                                    :items="Projects"
                                    :menu-props="{ offsetY: true }"
                                    :no-data-text="
                                      display.loading.projects
                                        ? 'Loading projects...'
                                        : 'No projects available'
                                    "
                                  >
                                  </v-autocomplete>
                                </div>
                              </template>
                              <!-- <div v-if="input.project">
                              <div class="d-flex justify-start pb-3">
                                <label class="text-h6">
                                  Who should receive weekly updates?
                                </label>
                              </div>
                              <div>
                                <v-autocomplete
                                  multiple
                                  chips
                                  deletable-chips
                                  :loading="display.loading.people"
                                  class="text-start text-left mb-4"
                                  :menu-props="{ offsetY: true }"
                                  :items="PeopleOptions"
                                  v-model="input.managers"
                                  placeholder="Start typing a name or email"
                                  :search-input.sync="misc.managerSearchInput"
                                  @change="misc.managerSearchInput = ''"
                                >
                                  <template v-slot:item="data">
                                    <div
                                      class="font-weight-bold text-left text-truncate"
                                    >
                                      {{ data.item.text }}
                                    </div>
                                  </template>
                                </v-autocomplete>
                              </div>
                            </div> -->
                              <div v-if="input.project">
                                <div class="d-flex justify-start pb-3">
                                  <label class="text-h6">
                                    Select your participant(s)
                                  </label>
                                </div>
                                <v-card flat outlined class="mb-5">
                                  <v-card-text>
                                    <!-- <div class="rewardees">
                                      <v-chip
                                        class="mx-2 my-2 audience-chip"
                                        v-for="(person, idx) in input.people"
                                        :key="person.id"
                                        close
                                        outlined
                                        color="brandCyan"
                                        @click:close="
                                          input.people.splice(idx, 1)
                                        "
                                      >
                                        <span class="black--text">{{
                                          person.name
                                        }}</span>
                                      </v-chip>
                                    </div> -->

                                    <div v-if="display.loading.people">
                                      <v-progress-circular
                                        color="primary"
                                        indeterminate
                                      ></v-progress-circular>
                                    </div>
                                    <div v-else>
                                      <div
                                        v-if="People.length && input.project"
                                      >
                                        <!-- <v-text-field
                                          v-model="input.filter"
                                          placeholder="Start typing a name or email"
                                          color="brandCyan"
                                        >
                                        </v-text-field> -->
                                        <ProcoreUsersTable
                                          :selected="input.people"
                                          :projectId="input.project"
                                          :CompanyIdParams="CompanyIdParams"
                                          :displaySearch="true"
                                          :People="People"
                                          @handle-error="errors.push($event)"
                                          @select-item="userRowClicked"
                                          @remove-item="
                                            input.people.splice($event, 1)
                                          "
                                        />
                                        <!-- <div
                                          v-for="person in MatchingPeople"
                                          :key="person.id"
                                        >
                                          <v-row
                                            align="center"
                                            class="person-selection cursor-pointer"
                                            @click="userRowClicked(person)"
                                          >
                                            <v-col
                                              cols="6"
                                              class="text-left body-2 d-flex align-center justify-start pl-5"
                                            >
                                              <div
                                                class="d-flex flex-column align-center"
                                              >
                                                <v-checkbox
                                                  inset
                                                  v-model="input.people"
                                                  :value="person"
                                                  @click.native="
                                                    $event.stopPropagation()
                                                  "
                                                  color="brandCyan"
                                                >
                                                </v-checkbox>
                                              </div>
                                              <span class="user-select-none">
                                                {{ person.name }}</span
                                              >
                                            </v-col>
                                            <v-col
                                              class="body-2 text-overflow-clip user-select-none"
                                              cols="6"
                                            >
                                              <v-row>
                                                {{ person | vendor }}</v-row
                                              >
                                              <v-row>
                                                {{ person | jobTitle }}</v-row
                                              >
                                            </v-col>
                                          </v-row>
                                        </div> -->
                                      </div>
                                      <div v-else class="d-flex flex-column">
                                        <span
                                          class="font-italic text--grey text--darken-1 pb-2"
                                        >
                                          No people found for project
                                        </span>
                                        <div>
                                          <!-- <v-btn depressed @click="prevStep" color="primary"
                                >Go Back</v-btn
                              > -->
                                        </div>
                                      </div>
                                    </div>
                                  </v-card-text>
                                </v-card>
                              </div>
                            </div>
                          </v-form>
                        </div>
                        <div v-else>
                          <div class="d-flex flex-column align-center">
                            <span class="text-h5 font-weight-bold px-7 my-5"
                              >We're autoconfiguring your program for optimal
                              results</span
                            >
                            <v-progress-circular
                              indeterminate
                              size="64"
                              width="8"
                              class="my-5"
                              color="deep-orange lighten-1"
                            />
                            <span class="body-2 grey--text my-5"
                              >This will only take a moment</span
                            >
                          </div>
                        </div>
                      </v-stepper-content>

                      <v-stepper-content step="2">
                        <v-form ref="step2form">
                          <div class="d-flex justify-start align-center pb-4">
                            <label class="text-h6">
                              Choose your requirements
                            </label>
                            <v-tooltip top v-if="!IsSubmittal"
                              ><template v-slot:activator="{ on, attrs }"
                                ><v-icon v-on="on" v-bind="attrs" class="ml-2"
                                  >mdi-help-circle-outline</v-icon
                                ></template
                              ><span v-if="input.resource !== 'Daily Logs'"
                                >In order for participants to get paid each
                                {{ CadenceUnit }}, {{ input.goal }}
                                {{ input.resource }} must be submitted inside
                                the daily log.</span
                              ><span v-else
                                >In order for participants to get paid each day,
                                all requirements below must be met along with
                                checking the box to "complete" the daily
                                log.</span
                              ></v-tooltip
                            >
                          </div>
                          <!-- <div class="d-flex justify-start pb-2">
                          <label class="text--disabled body-2">
                            Choose an action to reward
                          </label>
                        </div>
                        <div class="d-flex justify-space-between">
                          <v-select
                            :loading="display.loading.projects"
                            outlined
                            dense
                            v-model="input.resource"
                            color="brandCyan"
                            placeholder="Select a resource"
                            :items="fixtures.resources"
                            :menu-props="{ offsetY: true }"
                          >
                          </v-select>
                        </div> -->
                          <template v-if="!IsSubmittal">
                            <div class="d-flex justify-start pb-0"></div>
                            <div
                              class="d-flex justify-start align-end"
                              v-if="input.resource !== 'Daily Logs'"
                            >
                              <v-text-field
                                class="pr-1"
                                type="number"
                                outlined
                                dense
                                color="brandCyan"
                                style="max-width: 25%"
                                label="Goal"
                                v-model.number="input.goal"
                                placeholder=""
                                :rules="validation.goal"
                                :menu-props="{ offsetY: true }"
                              >
                              </v-text-field>
                              <div class="d-flex align-end pb-5 mb-4 mx-3">
                                logs per
                              </div>
                              <v-select
                                class="pl-1"
                                style="max-width: 35%"
                                outlined
                                dense
                                color="brandCyan"
                                v-model="input.cadence"
                                placeholder="Frequency"
                                :rules="validation.cadence"
                                :items="fixtures.cadences"
                                :menu-props="{ offsetY: true }"
                              >
                              </v-select>
                            </div>

                            <div v-else>
                              <div
                                v-if="DailyLogCadenceEditable"
                                class="d-flex align-center"
                              >
                                On a
                                <v-select
                                  class="pl-1"
                                  style="max-width: 25%"
                                  v-model="input.cadence"
                                  placeholder="Frequency"
                                  :rules="validation.cadence"
                                  :items="fixtures._cadences"
                                  :menu-props="{ offsetY: true }"
                                >
                                </v-select>
                                basis
                              </div>
                              <div class="d-flex flex-column mt-2 mb-4">
                                <div
                                  class="d-flex align-start"
                                  :key="index"
                                  v-for="(resource, index) in input.resources"
                                >
                                  <v-autocomplete
                                    style="max-width: 60%"
                                    outlined
                                    dense
                                    class="px-1"
                                    v-model="resource.resource"
                                    placeholder="Select a resource"
                                    item-text="text"
                                    item-value="value"
                                    :items="
                                      fixtures.dailyLogResources.filter(
                                        x =>
                                          (resource.resource &&
                                            x.value === resource.resource) ||
                                          !input.resources.find(
                                            y => y.resource === x.value
                                          )
                                      )
                                    "
                                  ></v-autocomplete>
                                  <v-text-field
                                    outlined
                                    dense
                                    style="max-width: 25%"
                                    min="0"
                                    max="9999"
                                    type="number"
                                    class="px-1 right-input"
                                    v-model.number="resource.goal"
                                    :rules="validation.goal"
                                  ></v-text-field>
                                  <v-btn
                                    rounded
                                    dark
                                    fab
                                    x-small
                                    class="mt-1 mx-2 elevation-0"
                                    color="secondary lighten-4"
                                    @click="input.resources.splice(index, 1)"
                                  >
                                    <v-icon small>mdi-trash-can</v-icon>
                                  </v-btn>
                                </div>
                                <v-btn
                                  color="primary"
                                  depressed
                                  @click="
                                    input.resources.push({
                                      resource: '',
                                      goal: 1
                                    })
                                  "
                                >
                                  Add field
                                </v-btn>
                              </div>
                            </div>
                          </template>
                          <template v-else>
                            <div class="d-flex align-start flex-column mb-2">
                              <span class="font-weight-bold">
                                Set your goal and incentives
                              </span>
                              <span class="body-1">
                                Choose the fields we should measure for
                                completion:
                              </span>
                            </div>
                            <div
                              class="d-flex align-center justify-start flex-column hide-scrollbar grey lighten-2 rounded-lg"
                              style="
                              width: 100%;
                              max-height: 15rem;
                              overflow-y: auto;
                            "
                              ref="submittalFields"
                            >
                              <div
                                class="d-flex justify-space-between"
                                style="position: sticky; top: 0px; width: 100%"
                              >
                                <br />
                                <span
                                  >Selected: {{ SubmittalFields.length }}</span
                                >
                                <div class="d-flex justify-end grey">
                                  <v-btn
                                    small
                                    icon
                                    class="align-self-end"
                                    @click="$refs.submittalFields.scrollTop = 0"
                                  >
                                    <v-icon> mdi-arrow-up </v-icon>
                                  </v-btn>
                                  <v-btn
                                    small
                                    icon
                                    class="align-self-end"
                                    @click="
                                      $refs.submittalFields.scrollTop =
                                        $refs.submittalFields.scrollHeight
                                    "
                                  >
                                    <v-icon> mdi-arrow-down </v-icon>
                                  </v-btn>
                                </div>
                              </div>
                              <v-row
                                align="start"
                                style="width: 100%"
                                v-for="(field, index) in input.fields"
                                :key="`${field}-${index}`"
                              >
                                <v-col cols="10" style="width: 100%">
                                  <v-autocomplete
                                    class="pt-1 mt-0"
                                    style="width: 100%"
                                    dense
                                    placeholder="Add New"
                                    v-model="input.fields[index]"
                                    :items="
                                      field
                                        ? ConfigurableFieldOptions
                                        : AvailableFields
                                    "
                                  />
                                </v-col>
                                <v-col cols="2">
                                  <v-btn
                                    v-if="input.fields[index]"
                                    icon
                                    @click="input.fields.splice(index, 1)"
                                  >
                                    <v-icon>mdi-trash-can</v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </div>
                            <div
                              class="d-flex justify-space-between align-start mb-2 mt-6"
                            >
                              <div class="d-flex flex-column align-start pt-2">
                                <label class="body-1">
                                  Percent completion goal:
                                </label>
                                <span
                                  class="body-1 caption grey--text text--darken-2"
                                >
                                </span>
                              </div>
                              <v-slider
                                persistent-hint
                                :min="1"
                                class="px-5"
                                step="1"
                                color="brandCyan"
                                :max="SubmittalFields.length"
                                thumb-label
                                @input="updateTotalFields"
                                :value="FieldsRequiredForCompletion"
                                ticks
                                :hint="
                                  `${input.goal}% (Total fields: ${FieldsRequiredForCompletion})`
                                "
                                :rules="validation.percent"
                              >
                              </v-slider>
                              <v-text-field
                                style="max-width: 15%"
                                class="mt-0 pt-0"
                                small
                                v-model.number="input.goal"
                                :max="100"
                                suffix="%"
                                :min="0"
                                type="number"
                                color="brandCyan"
                              ></v-text-field>
                            </div>
                          </template>
                        </v-form>
                      </v-stepper-content>
                      <v-stepper-content step="3">
                        <v-form ref="step2form">
                          <ProcoreRewardEditor
                            :IsSubmittal="IsSubmittal"
                            :isDailyLog="input.resource === 'Daily Logs'"
                            :IsCashBudget="IsCashBudget"
                            :LoadingBudgets="display.loading.budgets"
                            :reward="input.reward"
                            :rewardRules="validation.reward"
                            :streakBonus="input.ongoingStreakPayoutAmount"
                            :initialBonus="input.ongoingInitialPayoutAmount"
                            :bonusRules="validation.bonus"
                            :budgetId="input.rewardBudget"
                            :budgetRules="validation.budget"
                            :Budgets="BudgetOptions"
                            @update-reward="input.reward = $event"
                            @update-streak="
                              input.ongoingStreakPayoutAmount = $event
                            "
                            @update-initial="
                              input.ongoingInitialPayoutAmount = $event
                            "
                            @update-budget="input.rewardBudget = $event"
                          />
                          <div class="d-flex justify-end full-width mt-2">
                            <v-btn
                              v-if="Budget"
                              outlined
                              rounded
                              dark
                              x-small
                              color="primary"
                              @click="display.fundingBudget = true"
                              >Add funds</v-btn
                            >
                          </div>
                          <em
                            class="red--text text-left"
                            v-if="InsufficientFunds"
                          >
                            Warning: Insufficient funds - program may not pay
                            out. Please select a different budget or click "Add
                            funds"
                          </em>
                          <div
                            class="d-flex justify-start pt-2 pb-0 flex-column pr-1"
                          >
                            <label
                              class="text--disabled body-2 align-self-start"
                            >
                              Program timeframe
                            </label>
                            <div
                              class="d-flex justify-space-between align-center mt-3"
                            >
                              <v-menu
                                ref="start"
                                v-model="display.start"
                                :close-on-content-click="false"
                                min-width="auto"
                                transition="scale-transition"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <!-- style="max-width: 55%" -->
                                  <v-text-field
                                    v-model="DateText"
                                    class="mr-1"
                                    dense
                                    color="brandCyan"
                                    label="Start/End Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    outlined
                                    :rules="validation.dates"
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-if="display.start"
                                  class=""
                                  color="brandCyan"
                                  outlined
                                  show-adjacent-months
                                  :min="Now"
                                  v-model="input.dates"
                                  :type="DatePickerType"
                                  no-title
                                  range
                                >
                                </v-date-picker>
                              </v-menu>
                            </div>
                            <div class="d-flex justify-end">
                              <v-autocomplete
                                class="ml-1"
                                color="brandCyan"
                                style="max-width: 45%"
                                v-model="input.timezone"
                                label="Timezone"
                                placeholder="Timezone"
                                :items="fixtures.timezones"
                                outlined
                                dense
                              >
                              </v-autocomplete>
                            </div>
                          </div>
                          <v-divider />
                          <div
                            class="d-flex justify-space-between full-width mt-3"
                          >
                            <span>Max payout per person:</span
                            ><span class="font-weight-bold">
                              {{
                                {
                                  amount: ProgramCostPerPerson,
                                  type:
                                    Budget &&
                                    Budget.Award &&
                                    Budget.Award.awardName
                                } | award
                              }}</span
                            >
                          </div>
                          <div class="d-flex justify-space-between full-width">
                            <span>Max program payout:</span>
                            <span class="font-weight-bold">
                              {{
                                {
                                  amount: ProgramCost,
                                  type:
                                    Budget &&
                                    Budget.Award &&
                                    Budget.Award.awardName
                                } | award
                              }}</span
                            >
                          </div>
                          <!-- <div class="d-flex justify-center mt-5">
                          <label class="text-h6 mr-2"
                            >Total program payout:</label
                          >
                          <label class="text-h6">{{
                            {
                              amount: ProgramCost,
                              type:
                                Budget && Budget.Award && Budget.Award.awardName
                            } | award
                          }}</label>
                        </div> -->
                        </v-form>
                      </v-stepper-content>
                      <v-stepper-content step="4">
                        <div class="d-flex flex-column">
                          <div class="d-flex flex-column align-start">
                            <div
                              class="mb-2 d-flex justify-space-between align-center"
                              style="width: 100%"
                            >
                              <span class="text-h6 font-weight-bold text-left">
                                Does this look right?
                              </span>
                            </div>
                            <div class="d-flex justify-start">
                              <label class="pr-4"> Project: </label>
                              <div>
                                <v-card
                                  class="rounded-pill elevation-0"
                                  @click="display.configStep = 1"
                                >
                                  <v-alert
                                    outlined
                                    color="deep-orange lighten-1"
                                    class="rounded-pill text-no-wrap py-0 px-2"
                                  >
                                    {{ ProjectName }}
                                  </v-alert>
                                </v-card>
                              </div>
                            </div>
                            <div class="d-flex justify-start flex-wrap">
                              <label class="pr-4"
                                >{{ input.people?.length || 0 }} participant{{
                                  (input.people?.length || 0) > 1 ? "s" : ""
                                }}:</label
                              >
                              <div
                                v-for="person in input.people"
                                :key="person.id"
                                class="pr-2"
                              >
                                <v-card
                                  class="rounded-pill elevation-0"
                                  @click="display.configStep = 1"
                                >
                                  <v-alert
                                    outlined
                                    color="brandCyan"
                                    class="rounded-pill text-no-wrap py-0 px-2"
                                  >
                                    {{ person.name }}
                                  </v-alert>
                                </v-card>
                              </div>
                            </div>

                            <!-- <v-spacer class="my-1" /> -->
                            <!-- <div class="d-flex justify-start align-end my-2">
                            <label class="pr-4">Metric:</label>
                            <span v-if="input.resource !== 'Daily Logs'">
                              {{ input.resource }} in Daily Log
                            </span>
                            <span v-else>Daily Logs Completion</span>
                          </div> -->
                            <!-- <v-spacer class="my-3" /> -->
                            <div
                              class="d-flex justify-start align-end my-2"
                              v-if="IsSubmittal"
                            >
                              <label class="pr-4">Goal:</label>
                              <span>
                                <template
                                  v-if="
                                    SubmittalFields.length &&
                                      SubmittalFields.length > 1
                                  "
                                >
                                  {{ input.goal }}% completion ({{
                                    FieldsRequiredForCompletion
                                  }}/{{
                                    SubmittalFields.length
                                  }}
                                  fields)</template
                                >
                                <template v-else-if="SubmittalField">
                                  <span class="font-weight-bold">{{
                                    SubmittalField.text
                                  }}</span>
                                  must be filled out.
                                </template>
                              </span>
                            </div>
                            <div class="full-width d-flex flex-column" v-else>
                              <div
                                class="d-flex align-center"
                                v-if="input.resource === 'Daily Logs'"
                              >
                                <div
                                  class="slide-group-button text-center my-4"
                                >
                                  <v-btn
                                    depressed
                                    small
                                    width="140"
                                    height="30"
                                    :color="
                                      !input.userLevelAggregation
                                        ? 'white'
                                        : 'lightGrey'
                                    "
                                    class="mr-1"
                                    :class="{
                                      'team-program-selector': !input.userLevelAggregation
                                    }"
                                    @click="input.userLevelAggregation = false"
                                  >
                                    Team program
                                  </v-btn>
                                  <v-btn
                                    depressed
                                    small
                                    width="140"
                                    height="30"
                                    :color="
                                      input.userLevelAggregation
                                        ? 'white'
                                        : 'lightGrey'
                                    "
                                    class="ml-1"
                                    :class="{
                                      'team-program-selector':
                                        input.userLevelAggregation
                                    }"
                                    @click="input.userLevelAggregation = true"
                                  >
                                    User program
                                  </v-btn>
                                </div>
                                <v-tooltip top
                                  ><template v-slot:activator="{ on, attrs }"
                                    ><v-icon
                                      v-on="on"
                                      v-bind="attrs"
                                      class="ml-2"
                                      >mdi-help-circle-outline</v-icon
                                    ></template
                                  ><span v-if="input.userLevelAggregation"
                                    >Progress is tracked for each individual and
                                    each participant is only paid out when they
                                    have satisfied all the requirements
                                    themselves.</span
                                  ><span v-else
                                    >Progress is tracked for the project and
                                    anyone in the program is paid out when the
                                    goal is reached, regardless of who completed
                                    the requirements.
                                  </span></v-tooltip
                                >
                              </div>
                              <!-- <div class="d-flex align-center">
                                <span>Team program</span>
                                <v-switch
                                  v-model="input.userLevelAggregation"
                                  color="brandCyan"
                                  class="ml-2"
                                />
                                <span>Individual program</span>
                              </div> -->
                              <div
                                class="text-center font-italic full-width text-subtitle mb-1"
                              >
                                Program Requirements (per {{ CadenceUnit }})
                              </div>

                              <v-card
                                outlined
                                shaped
                                flat
                                class="pa-2 full-width rounded"
                              >
                                <div
                                  class="d-flex justify-space-between ml-10 mr-5"
                                >
                                  <span class="font-weight-black">
                                    Log type
                                  </span>
                                  <div class="d-flex align-center">
                                    <span class="font-weight-black"> Qty </span>
                                    <v-btn
                                      icon
                                      small
                                      class="ml-1"
                                      @click="display.configStep = 2"
                                      ><v-icon small>mdi-pencil</v-icon></v-btn
                                    >
                                  </div>
                                </div>
                                <div
                                  class="d-flex justify-space-between px-5 mx-5"
                                  v-for="(entry, index) in input.resource !==
                                  'Daily Logs'
                                    ? [
                                        {
                                          goal: input.goal,
                                          resource: input.resource
                                        }
                                      ]
                                    : [
                                        input.resources || [],
                                        [
                                          {
                                            resource: 'Daily Log Completion',
                                            goal: 1
                                          }
                                        ]
                                      ]?.flat()"
                                  :key="index"
                                >
                                  <span>{{
                                    {
                                      text: getFriendlyResourceName(
                                        entry.resource
                                      ),
                                      amount:
                                        48 -
                                        (getFriendlyResourceName(entry.resource)
                                          .length +
                                          ("" + entry.goal).length) /
                                          2
                                    } | padStart
                                  }}</span>
                                  <div class="ellipsis-fill"></div>
                                  <span>{{
                                    {
                                      text: "" + entry.goal,
                                      amount:
                                        48 -
                                        (getFriendlyResourceName(entry.resource)
                                          .length +
                                          ("" + entry.goal).length) /
                                          2
                                    } | padEnd
                                  }}</span>
                                </div>
                              </v-card>
                            </div>
                            <div class="d-flex align-center mt-5 pr-4">
                              <label class="body-1 font-weight-bold"
                                >Rewards
                              </label>
                              <v-btn
                                icon
                                small
                                class="ml-1"
                                @click="display.configStep = 3"
                                ><v-icon small>mdi-pencil</v-icon></v-btn
                              >
                            </div>
                            <div
                              class="d-flex justify-space-between full-width"
                            >
                              <label class="pr-2">Base Reward: </label>
                              <span
                                >{{
                                  {
                                    amount: input.reward,
                                    type:
                                      (Budget &&
                                        Budget.Award &&
                                        Budget.Award.awardName) ||
                                      "Cash"
                                  } | award
                                }}
                                <template v-if="!IsSubmittal"
                                  >per {{ CadenceUnit }}</template
                                ></span
                              >
                            </div>
                            <template v-if="!IsSubmittal">
                              <div
                                class="d-flex justify-space-between full-width"
                              >
                                <label class="pr-2">Initial Bonus: </label>
                                <span>{{
                                  {
                                    amount: input.ongoingInitialPayoutAmount,
                                    type:
                                      (Budget &&
                                        Budget.Award &&
                                        Budget.Award.awardName) ||
                                      "Cash"
                                  } | award
                                }}</span>
                              </div>
                              <div
                                class="d-flex justify-space-between full-width"
                              >
                                <label class="pr-2">Streak Bonus: </label>
                                <span>{{
                                  {
                                    amount: input.ongoingStreakPayoutAmount,
                                    type:
                                      (Budget &&
                                        Budget.Award &&
                                        Budget.Award.awardName) ||
                                      "Cash"
                                  } | award
                                }}</span>
                              </div>
                            </template>
                            <div
                              class="d-flex justify-space-between full-width mt-3"
                            >
                              <span>Max payout per person:</span
                              ><span class="font-weight-bold">
                                {{
                                  {
                                    amount: ProgramCostPerPerson,
                                    type:
                                      Budget &&
                                      Budget.Award &&
                                      Budget.Award.awardName
                                  } | award
                                }}</span
                              >
                            </div>
                            <div
                              class="d-flex justify-space-between full-width"
                            >
                              <span>Max program payout:</span>
                              <span class="font-weight-bold">
                                {{
                                  {
                                    amount: ProgramCost,
                                    type:
                                      Budget &&
                                      Budget.Award &&
                                      Budget.Award.awardName
                                  } | award
                                }}</span
                              >
                            </div>
                            <v-spacer class="my-3" />
                            <div class="d-flex align-center pr-4">
                              <label class="body-1 font-weight-bold"
                                >Details
                              </label>
                              <v-btn
                                icon
                                small
                                class="ml-1"
                                @click="display.configStep = 3"
                                ><v-icon small>mdi-pencil</v-icon></v-btn
                              >
                            </div>
                            <div
                              class="d-flex justify-space-between full-width"
                            >
                              <label class="pr-2">Timeframe: </label>
                              <span>{{ DateText }}</span>
                            </div>
                            <!-- <v-spacer class="my-3" /> -->
                            <div
                              class="d-flex flex-column align-start pa-0 ma-0"
                              style="width: 100%"
                            >
                              <div
                                class="d-flex justify-space-between"
                                style="width: 100%"
                              >
                                <template v-if="Budget">
                                  <label class="pr-2">Budget: </label>

                                  <span>{{ BudgetName }}</span>
                                </template>
                                <template v-else>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div
                                        @click="fillBudget"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <label class="pr-2 red--text"
                                          >Budget</label
                                        >
                                        <span class="red--text"
                                          >N/A (A budget is required)</span
                                        >
                                      </div>
                                    </template>
                                    <span> Click here to select a budget </span>
                                  </v-tooltip>
                                </template>
                              </div>
                              <div class="d-flex justify-end full-width mt-1">
                                <v-btn
                                  v-if="Budget"
                                  outlined
                                  rounded
                                  dark
                                  x-small
                                  color="primary"
                                  @click="display.fundingBudget = true"
                                  >Add funds</v-btn
                                >
                              </div>
                              <em
                                class="red--text text-left"
                                v-if="InsufficientFunds"
                              >
                                Warning: Insufficient funds - program may not
                                pay out. Please select a different budget or
                                click "Add funds"
                              </em>
                            </div>
                            <div v-if="input.project" class="full-width">
                              <div class="d-flex justify-start pb-3">
                                <label class="body-1">
                                  Who should receive weekly updates?
                                </label>
                              </div>
                              <div>
                                <v-autocomplete
                                  multiple
                                  chips
                                  outlined
                                  color="brandCyan"
                                  deletable-chips
                                  :loading="display.loading.people"
                                  class="text-start text-left mb-4"
                                  :menu-props="{ offsetY: true }"
                                  :items="PeopleOptions"
                                  v-model="input.managers"
                                  placeholder="Start typing a name or email"
                                  :search-input.sync="misc.managerSearchInput"
                                  @change="misc.managerSearchInput = ''"
                                >
                                  <template v-slot:item="data">
                                    <div
                                      class="font-weight-bold text-left text-truncate"
                                    >
                                      {{ data.item.text }}
                                    </div>
                                  </template>
                                </v-autocomplete>
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-stepper-content>
                      <v-stepper-content step="5">
                        <LoadingDialog
                          :showDialog="
                            (display.loading.registration &&
                              !display.editAudience &&
                              !display.editSchedule &&
                              !display.editReward) ||
                              display.loading.archive
                          "
                          :header="
                            display.loading.archive
                              ? 'Please wait while we archive your program'
                              : 'Please wait while we configure your reward'
                          "
                        ></LoadingDialog>
                        <label class="text-h6">
                          We'll take it from here!
                        </label>
                        <div class="d-flex justify-start pb-5">
                          We're building your program now. If you scheduled it
                          to start immediately, it will be active in a few
                          minutes, otherwise, it will be scheduled to start on
                          your chosen start date.
                        </div>
                        <div class="d-flex justify-start">
                          <p>
                            If you have any questions, reach out to
                            ben@wewhistle.com
                          </p>
                        </div>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </template>
              </template>
              <template v-else>
                <stripe-payment-methods-manager
                  :display-budget-transfer="false"
                  @reset="display.fundingBudget = false"
                  @get-budgets="getBudgets"
                  :budgets="data.budget"
                  :destination-budget="Budget"
                />
              </template>
            </v-navigation-drawer>
          </v-container>
        </v-card>
      </v-col>

      <v-col v-if="IsAdmin" lg="3" cols="12">
        <div class="d-flex flex-column align-start" style="height: 100%">
          <v-card class="mr-4 full-width elevation-1" outlined flat>
            <div class="d-flex flex-column align-start text-no-wrap py-2 px-4">
              <span
                class="pr-5 text-h5 font-weight-bold mt-1"
                style="
                  font-family: 'Avenir' !important;
                  font-size: 1.3rem !important;
                "
              >
                Add an Incentive or Reward
              </span>
              <v-card
                v-for="template in fixtures.templates"
                :key="template.name"
                flat
                outlined
                @click="configure(template)"
                style="width: 100%"
                class="my-2 raise"
              >
                <v-card-text>
                  <div class="d-flex justify-start" style="width: 100%">
                    <v-avatar
                      rounded
                      color="deep-orange lighten-1"
                      class="mr-4"
                    >
                      <v-icon x-large dark>mdi-{{ template.icon }} </v-icon>
                    </v-avatar>
                    <div
                      class="d-flex flex-column align-start"
                      style="width: 100%"
                    >
                      <span
                        class="text-md-h6 subtitle-1 black--text font-weight-bold text-left"
                        style="
                          font-family: 'Avenir' !important;
                          font-size: 1rem !important;
                        "
                      >
                        {{ template.title }}
                      </span>
                      <span class="body-2 text-left">
                        {{ template.desc }}
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
          <!-- <v-card
            outlined
            elevation="1"
            class="mt-5"
            width="100%"
            v-if="CanViewDataTab"
          >
            <div class="d-flex flex-column align-start text-no-wrap py-2 px-4">
              <span
                class="pr-5 text-h5 font-weight-bold mt-1"
                style="
                  font-family: 'Avenir' !important;
                  font-size: 1.3rem !important;
                "
              >
                Explore Your Data
              </span>
              <v-card
                flat
                outlined
                style="width: 100%"
                class="my-2 raise"
                to="/procore-semantic-search"
              >
                <v-card-text>
                  <div class="d-flex justify-start" style="width: 100%">
                    <v-avatar rounded color="brandCyan" class="mr-4">
                      <v-icon x-large dark>mdi-image-multiple </v-icon>
                    </v-avatar>
                    <div
                      class="d-flex flex-column align-start"
                      style="width: 100%"
                    >
                      <span
                        class="text-md-h6 subtitle-1 black--text font-weight-bold text-left"
                        style="
                          font-family: 'Avenir' !important;
                          font-size: 1rem !important;
                        "
                      >
                        Photo Search
                      </span>
                      <span class="body-2 text-left">
                        Explore photos searching with text or images
                      </span>
                    </div>
                  </div>
                </v-card-text></v-card
              >
            </div></v-card
          > -->
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "../axios";
import numeral from "numeral";
import LoadingDialog from "@/components/loadingDialog.vue";
import PillNav from "@/components/PillNav.vue";
import { mapState } from "vuex";
import dailyLogResources from "../shared_data/procore-daily-log-resources.json";
import tz from "../assets/timezones.json";
import { titleCase /* WORD_SEPARATORS */ } from "title-case";

import ProgramService from "@/services/ProgramService.js";
import BudgetService from "@/services/BudgetService.js";
import GroupService from "@/services/GroupService.js";

import ProcoreUsersTable from "@/components/data-table/ProcoreUsers";
import ProcoreRewardEditor from "@/components/procore/RewardEditor";
import ProcoreScheduleEditor from "@/components/procore/ScheduleEditor";
import ProcoreAudienceEditor from "@/components/procore/AudienceEditor";
import StripePaymentMethodsManager from "../components/stripe/StripePaymentMethodsManager.vue";
import MarqetaWidgetManagerV2 from "../components/wallet/MarqetaWidgetManagerV2.vue";

const america = tz
  .sort((a, b) => b.indexOf("America") - a.indexOf("America"))
  .filter(i => i.indexOf("America") !== -1)
  .reverse();
const rest = tz.filter(i => i.indexOf("America") === -1);
const timezones = [...america, ...rest];
const resourceTitleMap = {
  "Safety Violations": "Safety Reporting Incentive",
  Images: "Daily Log Photo Incentive",
  Submittals: "Submittal Completion Incentive",
  "Daily Logs": "Daily Log Completion Incentive"
};

const fmt = {
  asset: amount => numeral(amount).format("0,0.00"),
  amount: function(v) {
    const { amount, type } = v;
    const denom = {
      prefix: "",
      suffix: ""
    };
    if (type === "Cash") denom.prefix = "$";
    else if (type) denom.suffix = " " + type;
    return `${denom.prefix}${this.asset(amount)}${denom.suffix}`;
  }
};
import moment from "moment-timezone";

const MAX_ERRORS = 10;
const IGNORED_FIELDS = [
  "private",
  "sub_job_id",
  "specification_section_id",
  "submittal_log_status_id"
];
export default {
  name: "Procore",
  title: "Whistle | Procore",

  components: {
    LoadingDialog,
    PillNav,
    MarqetaWidgetManagerV2,
    StripePaymentMethodsManager,
    ProcoreRewardEditor,
    ProcoreScheduleEditor,
    ProcoreAudienceEditor,
    ProcoreUsersTable
  },
  mounted() {
    // alert("whoaoaaaaa");
    this.getCompanies();
  },
  filters: {
    padStart(v) {
      return v.text; //v.text.padStart(v.amount, ".");
    },
    padEnd(v) {
      return v.text; //v.text.padEnd(v.amount, ".");
    },
    award(v) {
      return fmt.amount(v);
    },
    asset(v) {
      return fmt.asset(v);
    },
    payoutFrequency(program) {
      switch (program.ruleAggregation.toLowerCase()) {
        case "daily":
          return "per day";
        case "weekly":
          return "per week";
        case "monthly":
          return "per month";
      }
    },
    vendor(person) {
      return person?.vendor?.name || "";
    },
    jobTitle(person) {
      return person?.job_title || "";
    },
    project(id, project) {
      return project?.name || "";
    },
    formattedDate({ date, timezone, formatString = "MMM Do, YYYY" }) {
      if (!date || !moment(date).isValid()) return "";
      return moment.tz(date, timezone).format(formatString);
    },
    programSupplementalProjectName(program) {
      return (
        (program?.config?.project?.project_number
          ? program?.config?.project?.project_number + " - "
          : "") + (program?.config?.project?.name || program?.displayName)
      );
    }
  },
  data() {
    return {
      cache: {},
      steps: [
        {
          step: 1,
          icon: "account-hard-hat"
        },
        {
          step: 2,
          icon: "clipboard-check-multiple"
        },
        {
          step: 3,
          icon: "trophy"
        },
        {
          step: 4,
          icon: "rocket-launch"
        }
      ],
      misc: {
        managerSearchInput: "",
        editingErrorMessage: null,
        vcurrency: {
          props: {
            locale: "en-US",
            decimalLength: 2,
            autoDecimalMode: true,
            min: null,
            max: null,
            defaultValue: 0,
            valueAsInteger: false,
            allowNegative: true
          }
        }
      },
      display: {
        showInactiveProjects: false,
        fundingBudget: false,
        configuration: false,
        configStep: 1,
        start: false,
        end: false,
        loaderValue: 0,
        loading: {
          step1: false,
          registration: false,
          programs: false,
          projects: false,
          people: false,
          budgets: false,
          archive: false
        },
        editReward: false,
        editSchedule: false,
        editAudience: false
      },
      input: {
        programId: null, // programId (only for editing)
        managers: [],
        project: null,
        company: null,
        filter: "",
        people: [],
        groups: [],
        resource: "Safety Violations", // ProgramElements.MetricDef.definition1
        cadence: "Daily", // ProgramElements.MetricDef.definition3
        goal: 1, // ProgramElements.value
        reward: 0, // payoutAmount
        rewardBudget: null, // Budget
        ongoingStreakPayoutAmount: 15,
        ongoingInitialPayoutAmount: 5,
        userLevelAggregation: false,
        startDate: null, // startDate
        endDate: null, // endDate
        endNow: false,
        programHasStarted: false,
        programHasEnded: false,
        dates: [],
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        fields: [null],
        resources: [
          {
            resource: "",
            goal: 3
          }
        ]
      },
      fixtures: {
        dailyLogResources,
        permissions: [
          {
            title: "Participant"
          },
          {
            title: "Manager"
          }
        ],
        timezones: timezones
          .sort((a, b) => b.indexOf("America") - a.indexOf("America"))
          .map(t => ({
            text: t,
            value: t
          })),
        resources: ["Images", "Safety Violations"]
          .map(i => ({
            text: i + " added to daily log",
            value: i
          }))
          .concat([
            {
              text: "Submittal field completion",
              value: "Submittals"
            },
            {
              text: "Daily Logs marked complete",
              value: "Daily Logs"
            }
          ]),
        cadences: [
          ["Day", "Daily"],
          ["Week", "Weekly"],
          ["Month", "Monthly"]
        ].map(([text, value]) => ({
          text,
          value
        })),
        _cadences: ["Daily", "Weekly", "Monthly"],
        templates: [
          {
            name: "Daily Logs",
            title: "Improve Daily Log Completion",
            icon: "notebook-multiple",
            desc: "Add incentives to improve Daily Log completion",
            presets: () => ({
              resource: "Daily Logs", // ProgramElements.MetricDef.definition1
              cadence: "Daily", // ProgramElements.MetricDef.definition3
              goal: 1, // ProgramElements.value
              reward: 5, // payoutAmount
              ongoingStreakPayoutAmount: 15,
              ongoingInitialPayoutAmount: 5,
              resources: [
                {
                  resource: "Manpower Logs",
                  goal: 3
                },
                {
                  resource: "Images",
                  goal: 8
                },
                {
                  resource: "Notes Logs"
                },
                {
                  resource: "Weather Logs"
                },
                {
                  resource: "Visitor Logs"
                }
              ].map(o => ({
                goal: 1,
                ...o
              })),
              dates: [
                moment()
                  .hours(0)
                  .minutes(0)
                  .seconds(0)
                  .milliseconds(0),
                moment()
                  .hours(0)
                  .minutes(0)
                  .seconds(0)
                  .milliseconds(0)
                  .add(4, "weeks")
              ].map(m => m.toDate().toISOString())
            })
          },
          {
            name: "Safety Violations",
            title: "Improve Safety and Reporting",
            icon: "account-hard-hat",
            desc: "Add incentives to improve safety reporting in the Daily Log",
            presets: () => ({
              resource: "Safety Violations", // ProgramElements.MetricDef.definition1
              cadence: "Daily", // ProgramElements.MetricDef.definition3
              goal: 3, // ProgramElements.value
              reward: 3, // payoutAmount
              ongoingStreakPayoutAmount: 15,
              ongoingInitialPayoutAmount: 5,
              dates: [
                moment()
                  .hours(0)
                  .minutes(0)
                  .seconds(0)
                  .milliseconds(0),
                moment()
                  .hours(0)
                  .minutes(0)
                  .seconds(0)
                  .milliseconds(0)
                  .add(4, "weeks")
              ].map(m => m.toDate().toISOString())
            })
          },
          {
            name: "Images",
            title: "Improve Photo Logs",
            icon: "account-hard-hat",
            desc:
              "Add incentives to improve site photo submissions in the Daily Log",
            presets: () => ({
              resource: "Images", // ProgramElements.MetricDef.definition1
              cadence: "Daily", // ProgramElements.MetricDef.definition3
              goal: 5, // ProgramElements.value
              reward: 5, // payoutAmount
              ongoingStreakPayoutAmount: 15,
              ongoingInitialPayoutAmount: 5,
              dates: [
                moment()
                  .hours(0)
                  .minutes(0)
                  .seconds(0)
                  .milliseconds(0)
                  .toDate(),
                moment()
                  .hours(0)
                  .minutes(0)
                  .seconds(0)
                  .milliseconds(0)
                  .add(4, "weeks")
                  .toDate()
                  .toISOString()
              ]
            })
          },
          {
            name: "Submittals",
            title: "Improve Submittal Completion",
            icon: "clipboard-check-outline",
            desc: "Incentivize completeness of submittal fields",
            presets: () => ({
              resource: "Submittals", // ProgramElements.MetricDef.definition1
              cadence: "Daily", // ProgramElements.MetricDef.definition3
              goal: 100, // ProgramElements.value
              reward: 100, // payoutAmount
              ongoingStreakPayoutAmount: 0,
              ongoingInitialPayoutAmount: 0,
              fields: [
                "number",
                "submittal_manager_id",
                "title",
                "specification_section",
                "description",
                // "",
                "status", // *
                "lead_time", // *
                "required_on_site_date", // *
                "responsible_contractor_id"
              ],
              dates: [
                moment()
                  .hours(0)
                  .minutes(0)
                  .seconds(0)
                  .milliseconds(0)
                  .toDate(),
                moment()
                  .hours(0)
                  .minutes(0)
                  .seconds(0)
                  .milliseconds(0)
                  .add(4, "months")
                  .toDate()
                  .toISOString()
              ]
            })
          }

          // {
          //   name: "idea",
          //   title: "Suggest an Incentive or Reward",
          //   icon: "lightbulb-on-10",
          //   desc: "Have a great idea for incentivizing behaviors in your organization? Let us know!",
          // },
        ]
      },
      data: {
        program: null,
        project: null,
        companies: null,
        people: null,
        budget: null,
        configurableFields: null,
        // Local cache of budget balances
        budgetBalances: {},
        programAudienceCountLoading: {},
        programAudienceCount: {}
      },
      errors: [],
      validation: {
        cadence: [v => Boolean(v) || "Cadence is required"],
        project: [v => Boolean(v) || "Project required"],
        company: [v => Boolean(v) || "Company required"],
        date: [v => v.indexOf("~") !== -1 || "Program date range required"],
        goal: [
          v => v >= 0 || "Goal amount required",
          v => v > 0 || "Goal amount must be greater than 0"
        ],
        reward: [
          v => v >= 0 || "Payout amount required",
          v => v > 0 || "Payout amount must be greater than 0",
          v =>
            (v.toString().split(".")?.[1]?.length || 0) <= 2 ||
            "Only two decimal places allowed"
        ],
        percent: [
          v => v >= 0 || "Percentage required",
          v =>
            (v > 0 && v <= 100) ||
            "Percentage must be greater than 0 and less than 100"
        ],
        bonus: [
          v => v >= 0 || "Bonus required",
          v =>
            (v.toString().split(".")?.[1]?.length || 0) <= 2 ||
            "Only two decimal places allowed"
          // (v) => v > 0 || "Bonus must be greater than 0",
        ],
        amount: [v => v >= 0 || "Amount required"],
        budget: [b => Boolean(b) || "Budget required"],
        recipient: [r => Boolean(r) || "Recipient required"],
        frequency: [r => Boolean(r) || "Payout frequency required"],
        start: [r => Boolean(r) || "Start date required"],
        end: [r => Boolean(r) || "End date required"]
      },
      expanded: []
    };
  },
  computed: {
    Headers() {
      return [
        { text: "Project", value: "project", sortable: true, width: "30%" },
        { text: "Program", value: "program", sortable: false, width: "20%" },
        {
          text: "Max payout",
          value: "reward",
          sortable: false,
          width: "20%"
        },
        { text: "Status", value: "status", width: "10%" },
        {
          text: "Details",
          value: "data-table-expand",
          width: "10%",
          align: this.IsAdmin ? undefined : " d-none"
        }
      ];
    },
    DailyLogCadenceEditable() {
      return false;
    },
    PeopleOptions() {
      return (this.People || []).map(p => ({
        text: `${p.name} ${p?.vendor?.name ? `(${p.vendor.name})` : ""}`,
        value: p
      }));
    },
    CompanyIdParams() {
      return {
        params: this.input.company ? { companyId: this.input.company } : {}
      };
    },
    MultiCompany() {
      return this.Companies?.length > 1;
    },
    Companies() {
      // if (!this.data.companies) {

      // }
      return this.data.companies || [];
    },
    GoalMax() {
      if (this.input.resource !== "Daily Logs") return 1000;
      else {
        if (this.input.cadence === "Weekly") return 7;
        else if (this.input.cadence === "Monthly") return 31;
        else if (this.input.cadence === "Daily") return 1;
        else return null;
      }
    },
    SubmittalField() {
      return this.ConfigurableFieldOptions.find(
        o => o.value === this.SubmittalFields[0]
      );
    },
    FieldsRequiredForCompletion() {
      return Math.ceil(this.SubmittalFields.length * (this.input.goal / 100));
    },
    ConfigurableFieldOptions() {
      return (
        (this.ConfigurableFields &&
          Object.entries(this.ConfigurableFields?.fields || {}).map(
            ([k, e]) => ({
              text: e?.label || titleCase((e?.name || k).replace(/_/gi, " ")),
              value: e?.name || k
            })
          )) ||
        []
      )
        .concat([
          {
            text: "Lead Time",
            value: "lead_time"
          },
          {
            text: "Status",
            value: "status"
          },
          {
            text: "Required On-Site Date",
            value: "required_on_site_date"
          },
          {
            text: "Specification Section",
            value: "specification_section"
          }
        ])
        .filter(f => !IGNORED_FIELDS.includes(f.value));
    },
    IsSubmittal() {
      return this.input.resource === "Submittals";
    },
    AvailableFields() {
      return this.ConfigurableFieldOptions.filter(
        f => this.SubmittalFields.indexOf(f.value) === -1
      );
    },
    SubmittalFields() {
      return this.input.fields.filter(Boolean);
    },
    IsCashBudget() {
      return this?.Budget?.Award?.awardName === "Cash";
    },
    Now() {
      return moment().format("YYYY-MM-DD");
    },
    ...mapState(["userProfile", "roles"]),
    DatePickerType() {
      return this.input.cadence.toLowerCase() === "monthly" ? "month" : "date";
    },
    StepOneLoading() {
      return this.display.loading.step1;
    },
    ProgramCostPerPerson() {
      const cadence = this.input.cadence.toLowerCase();
      const reward = Number(this.input.reward);
      if (this.IsSubmittal) return reward;
      const numPeriods =
        this.Dates.length === 2
          ? Math.abs(
              moment(this.Dates[1]).diff(
                this.Dates[0],
                cadence === "monthly"
                  ? "months"
                  : cadence === "weekly"
                  ? "weeks"
                  : "days"
              )
            ) + 1
          : 1;
      const initialBonus = Number(this.input.ongoingInitialPayoutAmount);
      const streakBonus = Number(this.input.ongoingStreakPayoutAmount);
      console.log({ reward, numPeriods, initialBonus, streakBonus });
      return reward * numPeriods + initialBonus + streakBonus;
    },
    ProgramCost() {
      const users = this.input.people.length;
      const costPerUser = this.ProgramCostPerPerson;
      console.log({ users, costPerUser });
      return costPerUser * users;
    },
    InsufficientFunds() {
      if (this.Budget && this.ProgramCost) {
        return this.Budget.balance < this.ProgramCost;
      } else {
        return false;
      }
    },
    CadenceUnit() {
      const cadence = this.fixtures.cadences.find(
        c => c.value === this.input.cadence
      );
      return cadence.text?.toLowerCase();
    },
    BudgetName() {
      return this.Budget ? this.Budget.budgetName : "N/A";
    },
    ProjectName() {
      return this.Project ? this.Project.name : "N/A";
    },
    ResourceTitle() {
      return this.input.resource && resourceTitleMap[this.input.resource];
    },
    ConfigurationTitle() {
      return this.display.editReward
        ? "Edit Reward Details"
        : this.display.editSchedule
        ? "Edit Program Timeframe"
        : this.display.editAudience
        ? "Edit Program Participants"
        : `Add a ${this.ResourceTitle}`;
    },
    ProgramEditSaveReady() {
      let ret = true;
      if (this.display.editReward && !this.StepThreeReady) ret = false;
      else if (
        this.display.editSchedule &&
        (this.HasInvalidDatesWhileEditing || !this.input.timezone)
      )
        ret = false;
      else if (this.display.editAudience && !this.input.people?.length)
        ret = false;
      return ret;
    },
    NextStepReady() {
      let ret = true;
      if (this.display.configStep === 1 && !this.StepOneReady) ret = false;
      else if (this.display.configStep === 2) ret = this.StepTwoReady;
      // this.$refs.step2form.validate()
      else if (this.display.configStep === 3 && !this.StepThreeReady)
        ret = false;
      // else if (this.display.configStep === 4 && !this.StepFourReady) {
      //   return false;
      // }
      // alert("Checking NextStepReady: " + ret);
      return ret;
    },
    // StepFourReady() {
    //   console.log("Budget", this.Budget);
    //   return ;
    // },
    Dates() {
      return this.input.dates
        .slice()
        .sort((a, b) => {
          return moment(a).unix() - moment(b).unix();
        })
        .map((m, i) =>
          this.input.cadence.toLowerCase() === "monthly"
            ? i === 1
              ? moment(m).endOf("month")
              : i === 0
              ? moment(m).startOf("month")
              : moment(m)
            : this.input.cadence.toLowerCase() === "weekly"
            ? i === 1
              ? moment(m).endOf("week")
              : i === 0
              ? moment(m).startOf("week")
              : moment(m)
            : i === 1
            ? moment(m).endOf("day")
            : moment(m)
        )
        .map(m => m.toDate().toISOString());
    },
    DateText() {
      console.log("Dates:", this.Dates.slice());
      const dates = [];
      if (moment(this.Dates?.[0]).unix() < moment().unix())
        dates.push(
          moment()
            .toDate()
            .toISOString()
        );
      else dates.push(this.Dates?.[0]);
      if (moment(this.Dates?.[1]).unix() < moment().unix())
        dates.push(
          moment()
            .toDate()
            .toISOString()
        );
      else dates.push(this.Dates?.[1]);
      const filtered = dates.filter(Boolean);
      console.log("Dates..", dates.length, filtered.length, {
        dates,
        filtered
      });
      return filtered.length === 2
        ? filtered
            .sort((a, b) => moment(a).unix() - moment(b).unix())
            .map(d => moment(d).format("MMM DD, YYYY"))
            .join(" to ")
        : "";
    },
    StepOneReady() {
      // return (
      //   this.input.project &&
      //   this.input.resource &&
      //   Number(this.input.goal) &&
      //   Number(this.input.reward) &&
      //   this.input.rewardBudget &&
      //   this.input.cadence &&
      //   this.$refs.form.validate()
      // );
      return !!(this.input.project && this.input.people.length);
    },
    StepTwoReady() {
      /**
       * (
        this.input.resource &&
          Number(this.input.goal) &&
          this.input.goal > 0 &&
          Number(this.input.reward) &&
          this.input.reward > 0 &&
          this.input.rewardBudget &&
          this.input.cadence &&
          this.input?.dates?.length === 2 &&
          (this.input.reward.toString().split(".")?.[1]?.length || 0) <= 2,
        (this.input.ongoingInitialPayoutAmount.toString().split(".")?.[1]
          ?.length || 0) <= 2,
        (this.input.ongoingStreakPayoutAmount.toString().split(".")?.[1]
          ?.length || 0) <= 2
      );
       */
      /*
        DC - revision 2
         this.input.resource &&
        this.input.reward &&
        this.input.reward > 0 &&
        this.input.rewardBudget &&
        (this.input.reward.toString().split(".")?.[1]?.length || 0) <= 2 &&
        (!this.IsSubmittal
          ? this.input.goal &&
            this.input.goal > 0 &&
            this.input.cadence &&
            (this.input.ongoingInitialPayoutAmount.toString().split(".")?.[1]
              ?.length || 0) <= 2 &&
            (this.input.ongoingStreakPayoutAmount.toString().split(".")?.[1]
              ?.length || 0) <= 2
          : this.SubmittalReady) &&
        this.input?.dates?.length === 2 &&
        (this.input.resource !== "Daily Logs" ||
          (this.input.resources.length &&
            this.input.resources.every(r => r.resource && r.goal)))

      */
      return (
        this.input.resource &&
        (!this.IsSubmittal
          ? this.input.goal && this.input.goal > 0 && this.input.cadence
          : this.SubmittalReady) &&
        (this.input.resource !== "Daily Logs" ||
          (this.input.resources.length &&
            this.input.resources.every(
              r => r.resource && r.goal && r.goal > 0
            )))
      );
    },
    SubmittalReady() {
      return (
        this.SubmittalFields.length && this.input.goal && this.input.goal > 0
      );
    },
    ValidDates() {
      return !!(this.input?.dates?.length === 2);
    },
    StepThreeReady() {
      return !!(
        this.input.reward &&
        this.input.reward > 0 &&
        this.input.rewardBudget &&
        (this.input.reward?.toString()?.split(".")?.[1]?.length || 0) <= 2 &&
        this.Budget &&
        (this.input.ongoingStreakPayoutAmount.toString().split(".")?.[1]
          ?.length || 0) <= 2 &&
        Number(this.input.ongoingStreakPayoutAmount || 0) >= 0 &&
        Number(this.input.ongoingInitialPayoutAmount || 0) >= 0 &&
        (this.input.ongoingInitialPayoutAmount.toString().split(".")?.[1]
          ?.length || 0) <= 2 &&
        (this.display.editReward || this.input?.dates?.length === 2)
      );
    },
    IsAdmin() {
      const roles = ["40ClientAdminV2", "03ProgramAdminV2"];
      return !!this.roles.find(x => roles.includes(x));
    },
    IsEditingProgram() {
      return (
        this.display.editReward ||
        this.display.editAudience ||
        this.display.editSchedule
      );
    },
    Project() {
      return this.Projects.find(p => p.id === this.input.project);
    },
    Programs() {
      if (!this.data.program) {
        this.getPrograms();
      }
      return this.data.program || [];
    },
    ConfigurableFields() {
      if (!this.data.configurableFields) {
        this.getConfigurableFields();
      }
      return this.data.configurableFields || {};
    },
    Projects() {
      // if (!this.data.project) {
      //   this.getProjects();
      // }
      const ret =
        this?.data?.project?.map?.(v => ({
          ...v,
          desc: `${v.project_number ? `[${v.project_number}] ` : ""}${v.name}`
        })) || [];
      if (!this.display.showInactiveProjects) {
        return ret.filter(
          (
            v //
          ) => v?.status?.toLowerCase?.() === "active"
        );
      } else return ret;
    },
    Budgets() {
      if (!this.data.budget) {
        this.getBudgets();
      }
      return this.data.budget || [];
    },
    People() {
      // if (!this.data.people) {
      //   this.getPeople();
      // }
      return this.data.people || [];
    },
    Budget() {
      return this.Budgets.find(b => b.budgetId === this.input.rewardBudget);
    },
    BudgetOptions() {
      return this.Budgets.map(budget => ({
        text: `${budget.budgetName} (${fmt.amount({
          amount: budget.balance,
          type: budget.Award.awardName
        })})`,
        value: budget.budgetId
      }));
    },
    // MatchingPeople() {
    //   return this.People.filter(p => {
    //     return (
    //       [p.name, p?.vendor?.name, p?.job_title, p.email_address]
    //         .filter(Boolean)
    //         .join(" ")
    //         .toLowerCase()
    //         .indexOf(this.input.filter.toLowerCase()) !== -1
    //     );
    //   });
    // },
    CanViewDataTab() {
      return this?.userProfile?.businessEmail?.includes("@wewhistle.com");
    },
    HasInvalidDatesWhileEditing() {
      if (!this.display.editSchedule) return null;

      if (!this.input.startDate) return "A start date is required";
      else if (!this.input.endDate) return "An end date is required";

      const start = moment(this.input.startDate);
      const end = this.input.endNow ? moment() : moment(this.input.endDate);

      return start.isAfter(end)
        ? "End date must be after the start date"
        : null;
    }
  },
  watch: {
    "input.company": async function(n, o) {
      // Wipe project if company is changing
      this.input.project = null;
      this.data.people = null;
      this.getProjects();
      // this.getPeople();
    },
    "input.resource": function(n, o) {
      // Editing a program doesn't need any configuration setup
      if (this.IsEditingProgram) return;

      if (n !== o && n) {
        this.configure(
          this.fixtures.templates.find(t => t.name === this.input.resource)
        );
      }
    },
    "input.fields": {
      deep: true,
      handler(/* n, o */) {
        if (this.input.fields[this.input.fields.length - 1] !== null) {
          this.input.fields.push(null);
          console.log(this.$refs.submittalFields);
          setTimeout(() => {
            this.$refs?.submittalFields?.scrollTop &&
              (this.$refs.submittalFields.scrollTop = this.$refs?.submittalFields?.scrollHeight);
          }, 50);
        }
      }
    },
    "input.project": function(n, v) {
      if (n !== v) {
        // this.data.people = null;
        if (this.IsSubmittal) this.getConfigurableFields();
        this.input.people = [];
        this.data.people = null;
        this.getPeople();
      }
    },
    "input.cadence": function(n) {
      if (this.input.resource == "Daily Logs" && n === "Daily") {
        this.input.goal = 1;
      }
      this.input.dates = [
        moment()
          .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0),
        moment()
          .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .add(4, "weeks")
      ].map(m => m.toDate().toISOString());
    },
    "display.configuration": function(n /* v */) {
      this.errors = [];
      if (!n) {
        this.display.configStep = 1;
        this.display.fundingBudget = false;
        this.display.editReward = false;
        this.display.editSchedule = false;
        this.display.editAudience = false;
        this.misc.editingErrorMessage = null;
        Object.assign(this.input, {
          managers: [],

          project: null,
          filter: "",
          people: [],
          groups: [],
          cadence: "Daily", // ProgramElements.MetricDef.definition3
          goal: 0, // ProgramElements.value
          reward: 0, // payoutAmount
          rewardBudget: null, // Budget
          ongoingStreakPayoutAmount: 0,
          ongoingInitialPayoutAmount: 0,
          userLevelAggregation: false,
          startDate: null, // startDate
          endDate: null, // endDate
          dates: [],
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          fields: [null],
          resources: [
            {
              resource: "",
              goal: 3
            }
          ]
        });
        if (this.data.budget) this.preloadBudget();

        this.$refs.form?.resetValidation?.();
      }
    }
  },
  methods: {
    setPermission(permission, person) {
      console.log({ permission, person });
    },
    openPlatform() {
      window.open("/", "_blank");
    },
    programRowClick(item) {
      if (!this.IsAdmin) return;
      let value = item?.item || item;
      console.log(value);

      //add or remove the item from expanded array
      const index = this.expanded.findIndex(
        x => x?.programId == value?.programId
      );
      if (index === -1) {
        // Prevents someone from trying to edit a program that just ended
        if (value?.endDate && moment(value?.endDate).isBefore(moment()))
          value.status = "Archived";
        this.expanded.push(value);

        if (this.data.programAudienceCount[value.programId] === undefined) {
          const groups = item?.groups || [];
          if (!groups.length)
            this.data.programAudienceCount[value.programId] = 0;
          else {
            this.$set(
              this.data.programAudienceCountLoading,
              value.programId,
              true
            );
            // this.data.programAudienceCountLoading[value.programId] = true;
            GroupService.getDistinctUsersFromGroupsV2(groups, [])
              .then(response => {
                console.log("Distinct user count: ", response);
                this.$set(
                  this.data.programAudienceCountLoading,
                  value.programId,
                  false
                );
                this.$set(
                  this.data.programAudienceCount,
                  value.programId,
                  response
                );
                // Find it in the array and update that too
                // const program = this.expanded.find(
                //   x => x?.programId == value?.programId
                // );
                // program.audienceCount = response;
                // Set the program to itself so it re-renders
                // this.$set(program, {
                //   ...program
                // });
              })
              .catch(e => {
                console.log("Failed to get distinct user count for program", e);
              })
              .finally(() => {
                this.$set(
                  this.data.programAudienceCountLoading,
                  value.programId,
                  false
                );
              });
          }
        }

        // if (
        //   value.budgetId &&
        //   this.data.budgetBalances[value.budgetId] === undefined
        // ) {
        //   BudgetService.getBudgetsV2({}, value.budgetId)
        //     .then(response => {
        //       console.log({ budgetId: value.budgetId, response });
        //       this.$set(
        //         this.data.budgetBalances,
        //         value.budgetId,
        //         response?.data?.result?.balance || 0
        //       );
        //     })
        //     .catch(e => {
        //       console.log("Failed to fetch budget balance", e);
        //     });
        // }
      } else {
        this.expanded = this.expanded.filter(
          x => x?.programId != value?.programId
        );
      }
    },
    getFriendlyResourceName(val, overrideDailyLogResource = false) {
      if (!val || !val?.length) return "";

      if (overrideDailyLogResource && val === "Daily Logs")
        return "Daily Log Completion";
      return (
        this.fixtures.dailyLogResources.find(x => x.value == val)?.text || val
      );
    },
    updateTotalFields(v) {
      v = Number(v);
      this.input.goal = Math.min(
        Math.round((v / this.SubmittalFields.length) * 100),
        100
      );
    },
    async fillBudget() {
      this.display.configStep = 2;
      await this.$nextTick();
      this.$refs?.step2form?.validate();
    },
    edit() {
      this.display.configStep--;
    },
    save(menu, date) {
      this.$refs[menu].save(date);
    },
    async nextStep(step) {
      console.log("nextStep()", { step, currentStep: this.display.configStep });
      if (
        (typeof step === "number" &&
          step > this.display.configStep &&
          !this.NextStepReady) ||
        (step >= 2 && !this.StepOneReady) ||
        (step >= 3 && !this.StepTwoReady)
      )
        return;
      if (this.display.configStep === 5) {
        this.stopConfiguring();
        return;
      }
      console.log("Made it here!");
      const initialStep = this.display.configStep;
      if (typeof step === "number") {
        // if (this.display.configStep !== 3 && step === 4) return;
        this.display.configStep = step;
      } else {
        this.display.configStep++;
      }
      if (this.display.configStep === 5) {
        console.log("here we r");
        this.display.configStep = initialStep;
        if (
          initialStep === 4 &&
          this.StepOneReady &&
          this.StepTwoReady &&
          this.StepThreeReady
          // this.StepFourReady
        ) {
          await this.completeRegistration();
          this.display.configStep = 5;
        }
      }
    },
    prevStep() {
      this.display.configStep--;
    },
    async configure(template) {
      // alert("configuring...");
      // console.log('here...', new Error().stack);
      if (template?.name === "idea") {
        return;
      }
      if (this.display.configuration) {
        this.display.configuration = false;
        await this.$nextTick();
      }
      this.display.configuration = true;
      const preset =
        typeof template.presets === "function" ? template.presets() : template;
      Object.assign(this.input, preset);
      console.log({ this: this });
    },
    stopConfiguring() {
      // alert("stop configuring...");
      this.display.configuration = false;
      this.display.configStep = 1;

      // this.data.people = null;
    },

    async getPrograms() {
      if (this.errors.filter(i => i === "programs").length >= MAX_ERRORS)
        return;
      if (this.display.loading.programs) return;
      this.display.loading.programs = true;

      return ProgramService.getProcorePrograms(this.CompanyIdParams)
        .then(res => {
          this.data.program = res.data.rows.map(p => {
            p.config = p?.ProgramSupplementals?.filter(
              x => x.status !== "Deleted" && x.key === "Procore/info"
            )?.[0]?.value;
            p.projectId = p?.config?.project?.id;
            // p.groups =
            //   p?.ProgramGroups?.filter(x => x.status === "Active")?.map(
            //     x => x.groupId
            //   ) || [];

            if (
              p.status == "Scheduled" &&
              p?.startDate &&
              moment(p?.startDate).isBefore(moment())
            )
              p.status = "Active";
            // Prevents someone from trying to edit a program that just ended
            if (p?.endDate && moment(p?.endDate).isBefore(moment()))
              p.status = "Archived";

            const isSubmittal =
              p.config?.resource === "Submittals" ||
              p.ruleAggregation === "ONE_TIME";
            const initialBonus = Number(p.ongoingInitialPayoutAmount);
            const streakBonus = Number(p.ongoingStreakPayoutAmount);
            const cadence = p.ruleAggregation.toLowerCase();
            const reward = Number(p.payoutAmount);
            const numPeriods =
              p.startDate && p.endDate
                ? Math.abs(
                    moment(p.endDate).diff(
                      p.startDate,
                      cadence === "monthly"
                        ? "months"
                        : cadence === "weekly"
                        ? "weeks"
                        : "days"
                    )
                  ) + 1
                : 1;
            p.maxProgramCostPerUser = isSubmittal
              ? reward
              : reward * numPeriods + initialBonus + streakBonus;
            return p;
          });
        })
        .catch(() => {
          this.errors.push("programs");
        })
        .finally(() => {
          this.display.loading.programs = false;
        });
    },
    async getConfigurableFields() {
      if (this.display.loading.configurableFields) return;
      if (!this.input.project) return;
      this.display.loading.configurableFields = true;
      if (this.cache?.[this.input.project]?.fields) {
        this.data.configurableFields = this.cache?.[this.input.project]?.fields;
        this.input.fields = this.input.fields.filter(f =>
          this.ConfigurableFieldOptions.find(cf => cf?.value === f)
        );
      }
      return ProgramService.getProcoreConfigurableFields(
        this.input.project,
        this.CompanyIdParams
      )
        .then(res => {
          this.data.configurableFields = res.data;
          if (!this.cache?.[this.input.project])
            this.cache[this.input.project] = {};
          this.cache[this.input.project].fields = res.data;
          this.input.fields = this.input.fields.filter(f =>
            this.ConfigurableFieldOptions.find(cf => cf?.value === f)
          );
        })
        .finally(() => {
          this.display.loading.configurableFields = false;
        });
    },
    async getCompanies(forceRefresh = false) {
      if (this.errors.filter(i => i === "companies").length >= MAX_ERRORS)
        return;
      this.$set(this.display.loading, "companies", true);
      return ProgramService.getProcoreCompanies({
        params: { refresh: forceRefresh ? 1 : 0 }
      })
        .then(res => {
          this.data.companies = res.data;
          if (!this.input.company) this.input.company = res.data[0].id;
        })
        .catch(() => {
          this.errors.push("companies");
        })
        .finally(() => {
          this.$set(this.display.loading, "companies", false);
        });
    },
    async getProjects() {
      if (this.errors.filter(i => i === "projects").length >= MAX_ERRORS)
        return;
      if (this.display.loading.projects) return;

      this.display.loading.projects = true;
      try {
        let opts = JSON.parse(JSON.stringify(this.CompanyIdParams));
        if (!opts?.params) opts.params = {};
        const res = await ProgramService.getProcoreProjects(opts);
        const projectsArray = res.data;
        console.log("Got projects", projectsArray);
        this.data.project = projectsArray;
        // Set projects false so it's usable while it's fetching fresh data
        this.display.loading.projects = false;

        // Now re-fetch projects and force reload it
        opts.params.refresh = 1;
        const secondRes = await ProgramService.getProcoreProjects(opts);
        const secondProjectsArray = secondRes.data;
        console.log("Got projects again", secondProjectsArray);
        this.data.project = secondProjectsArray;
      } catch (e) {
        console.log("Error in getProjects", e);
        this.errors.push("projects");
      } finally {
        this.display.loading.projects = false;
      }
    },
    async getBudgets() {
      if (this.errors.filter(i => i === "budgets").length >= MAX_ERRORS) return;
      if (this.display.loading.budgets) return;

      this.display.loading.budgets = true;
      return BudgetService.getBudgetsV2({ expand: "Award", limit: 200 })
        .then(res => {
          this.data.budget = res.data.result.rows;
          this.preloadBudget();
        })
        .catch(() => {
          this.errors.push("budgets");
        })
        .finally(() => {
          this.display.loading.budgets = false;
        });
    },
    preloadBudget() {
      if (this.input.rewardBudget) return;
      const clientBudgets = this.data.budget.filter(
        x =>
          x.clientId == this.userProfile?.clientId &&
          x.status == "Active" &&
          Number(x.balance) > 0
      );

      const acctBalance = clientBudgets.find(x => x.masterBudget);
      const cashBudget = clientBudgets.find(x => x.awardId == 1);
      if (acctBalance) this.input.rewardBudget = acctBalance.budgetId;
      else if (cashBudget) this.input.rewardBudget = cashBudget.budgetId;
      else if (clientBudgets.length)
        this.input.rewardBudget = clientBudgets[0].budgetId;
      else if (this.data.budget.length)
        this.input.rewardBudget = this.data.budget[0].budgetId;
    },
    async getPeople(forceRefresh = false) {
      console.log("Calling getPeople(" + forceRefresh + ")");
      if (this.errors.filter(i => i === "people").length >= MAX_ERRORS) return;
      if (this.display.loading.people && !forceRefresh) return;

      if (!this.input.project)
        return console.log("Ignoring getPeople() request due to no company");
      // if (!this.input.project) return;
      if (this.cache?.[this.input.project]?.people && !forceRefresh) {
        this.data.people = this.cache?.[this.input.project]?.people;
        return this.data.people;
      }
      this.display.loading.people = true;
      const PARTICIPANT = 0;
      let opts = JSON.parse(JSON.stringify(this.CompanyIdParams));
      if (!opts?.params) opts.params = {};
      // Now re-fetch people and force reload it
      if (forceRefresh) opts.params.refresh = 1;
      return ProgramService.getProcorePeopleInProject(this.input.project, opts)
        .then(res => {
          this.data.people = res.data.map(p => ({
            ...p,
            $permission: PARTICIPANT
          }));
          if (!this.cache[this.input.project])
            this.cache[this.input.project] = {};
          this.cache[this.input.project].people = this.data.people.slice();

          if (!forceRefresh) this.getPeople(true);
        })
        .catch(() => {
          this.errors.push("people");
        })
        .finally(() => {
          this.display.loading.people = false;
          // alert("done loading people");
        });
    },
    async completeRegistration() {
      if (this.display.loading.registration) return;

      if (this.input.dates.length !== 2) return;
      this.input.resources = this.input.resources.filter(
        r => r.resource && r.goal
      );
      const dates = [];
      if (moment(this.Dates?.[0]).unix() < moment().unix())
        dates.push(
          moment()
            .toDate()
            .toISOString()
        );
      else dates.push(this.Dates?.[0]);
      if (moment(this.Dates?.[1]).unix() < moment().unix())
        dates.push(
          moment()
            .toDate()
            .toISOString()
        );
      else dates.push(this.Dates?.[1]);

      const cadence = this.input.cadence.toLowerCase();
      const period =
        cadence === "monthly" ? "month" : cadence === "weekly" ? "week" : "day";
      dates[0] = moment(dates[0])
        .utcOffset(moment.tz(this.input.timezone).utcOffset())
        .milliseconds(0)
        .seconds(0)
        .minutes(0)
        .hours(0)
        .utcOffset(0)
        .toDate()
        .toISOString();
      dates[1] = moment(dates[1])
        .utcOffset(moment.tz(this.input.timezone).utcOffset())
        .milliseconds(0)
        .seconds(0)
        .endOf(period)
        .hours(23)
        .minutes(59)
        .seconds(59)
        .utcOffset(0)
        .toDate()
        .toISOString();
      this.input.startDate = dates[0];
      this.input.endDate = dates[1];

      this.display.loading.registration = true;
      if (this.input.resource === "Daily Logs") {
        this.input.cadence = "Daily";
        this.input.goal = 1;
      }

      // We don't need to re-fetch the user since we have it in the app already
      // const user = await UserService.userProfileByEmail();
      let user = JSON.parse(JSON.stringify(this.userProfile));
      for (const key in user) {
        if (Object.hasOwn(user, key)) {
          if (Array.isArray(user[key])) delete user[key];
        }
      }

      await ProgramService.procoreCompleteRegistration(
        {
          user,
          project: this.Project,
          participants: this.input.people.map(p => ({
            ...p,
            $permission: this.fixtures.permissions[p.$permission]
          })),
          misc: {
            ...this.input,
            fields: this.SubmittalFields,
            cadence: this.IsSubmittal ? "ONE_TIME" : this.input.cadence,
            fieldLabels: this.SubmittalFields.map(
              f => this.ConfigurableFieldOptions.find(o => o.value === f).text
            )
          }
        },
        this.CompanyIdParams
      ).finally(async () => {
        await this.getPrograms().finally(() => {
          this.display.loading.registration = false;
        });
      });
    },
    userRowClicked(user) {
      let index = this.input.people.findIndex(x => x.id === user?.id);
      if (index === -1) this.input.people.push(user);
      else this.input.people.splice(index, 1);
    },
    editProgram(type, program) {
      // Daily Log and Submittal initi
      this.input.resource = program?.config?.resource;

      console.log("Editing", { program });
      if (!program?.programId) return;
      this.input.programId = program.programId;
      this.input.project = program.projectId;
      // Rewards
      if (type === "editReward") {
        this.input.rewardBudget = program?.budgetId;
        this.input.reward = program?.payoutAmount;
        this.input.ongoingStreakPayoutAmount =
          program?.ongoingStreakPayoutAmount;
        this.input.ongoingInitialPayoutAmount =
          program?.ongoingInitialPayoutAmount;
      }
      // Schedule
      else if (type === "editSchedule") {
        this.input.startDate = program?.startDate;
        this.input.endDate = program?.endDate;
        this.input.timezone = program?.timezone;
        this.input.cadence = program?.ruleAggregation;
        this.input.endNow = false;
        this.input.programHasStarted = moment(
          program?.startDate
        ).isSameOrBefore(moment());
        this.input.programHasEnded = moment(program?.endDate).isSameOrBefore(
          moment()
        );
      } else if (type == "editAudience") {
        this.input.groups = program?.groups || [];
      }

      this.display.configuration = true;
      this.display[type] = true;
    },
    async updateProgram(audienceArray = [], archiveProgramId = null) {
      const loadingKey = archiveProgramId ? "archive" : "registration";
      this.misc.editingErrorMessage = null;
      this.misc.archiveProgramFailure = false;
      let bod;
      if (this.display.editReward) {
        bod = {
          payoutAmount: this.input.reward,
          ongoingStreakPayoutAmount: this.input.ongoingStreakPayoutAmount,
          ongoingInitialPayoutAmount: this.input.ongoingInitialPayoutAmount,
          budgetId: this.input.rewardBudget
        };
      } else if (this.display.editSchedule) {
        let start, end;
        const cadence = this.input.cadence.toLowerCase();
        const period =
          cadence === "monthly"
            ? "month"
            : cadence === "weekly"
            ? "week"
            : "day";

        if (!this.input.programHasEnded)
          end = this.input.endNow
            ? moment()
                .utcOffset(moment.tz(this.input.timezone).utcOffset())
                .toDate()
                .toISOString()
            : moment(this.input.endDate)
                .utcOffset(moment.tz(this.input.timezone).utcOffset())
                .milliseconds(0)
                .seconds(0)
                .endOf(period)
                .hours(23)
                .minutes(59)
                .seconds(59)
                .utcOffset(0)
                .toDate()
                .toISOString();
        if (!this.input.programHasStarted)
          start = !this.input.startDate
            ? undefined
            : (start = moment(this.input.startDate)
                .utcOffset(moment.tz(this.input.timezone).utcOffset())
                .milliseconds(0)
                .seconds(0)
                .minutes(0)
                .hours(0)
                .utcOffset(0)
                .toDate()
                .toISOString());
        if (start || end)
          bod = {
            startDate: start,
            endDate: end,
            timezone: this.input.timezone
          };
      } else if (this.display.editAudience && audienceArray?.length) {
        bod = {
          users: audienceArray
        };
      }
      console.log("Updating program ", bod);
      if ((bod && this.input.programId) || archiveProgramId) {
        const editType = this.display.editReward
          ? "reward"
          : this.display.editAudience
          ? "audience"
          : archiveProgramId
          ? "archive"
          : "schedule";
        this.display.loading[loadingKey] = true;
        await ProgramService.procoreUpdateRegistration(
          archiveProgramId || this.input.programId,
          editType,
          bod
        )
          .then(async r => {
            console.log("Updated program", r);
            if (this.display.editAudience)
              this.data.programAudienceCount[this.input.programId] = undefined;

            // Close all open programsso proper data refreshes can be triggered on row open
            this.expanded = [];
            await this.getPrograms().finally(() => {
              this.stopConfiguring();
            });
          })
          .catch(e => {
            console.log("Error updating program", e);
            this.misc.editingErrorMessage =
              "We encountered an unexpected error. Please try again or contact Whistle for assistance.";
            if (archiveProgramId) {
              // If it's an archived program, we technically show this error message on any visible program, so rather than coding to handle errors based on programId, we're just setting a timeout to clear it after 4 seconds
              setTimeout(() => {
                this.misc.editingErrorMessage = null;
              }, 4000);
            }
          })
          .finally(async () => {
            this.display.loading[loadingKey] = false;
          });
      } else this.stopConfiguring();
    }
  }
};
</script>

<style scoped>
.raise:hover,
.raise:focus {
  box-shadow: 0 0.25em 0.25em -0.4em black;
  transform: translateY(-0.05em);
}

.person-selection {
  border-bottom: 1px solid rgb(203, 213, 219);
}
.flat {
  box-shadow: none !important;
  border: none;
}

/* Styling for data table */
.table {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}
.table >>> .v-data-table-header {
  background-color: white;
}
.text-h5 {
  font-family: "Avenir" !important;
}
.text-h6 {
  font-family: "Avenir" !important;
}
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.right-input >>> input {
  text-align: right !important;
}
.ellipsis-between {
  white-space: nowrap; /* Prevent wrapping onto multiple lines */
}

.ellipsis-between::after {
  content: "..."; /* Add the ellipsis content */
  padding: 0 0.2em; /* Add spacing around the ellipsis */
  display: none; /* Initially hide the ellipsis */
}

.ellipsis-between:hover .ellipsis-between::after {
  display: inline; /* Show ellipsis on hover */
}
.v-list-item__content {
  text-align: left !important;
}
.ellipsis-fill {
  flex-shrink: 0 !important;
  flex-grow: 1 !important;
  border-bottom: dotted black 1px;
  transform: translateY(-7px);
}

/* Puts cursor on each individual row to show it's clickable */
#table >>> .v-data-table__wrapper > table > tbody > tr {
  cursor: pointer;
}

#table >>> .v-data-table__expanded__content {
  box-shadow: none;
  background-color: #eeeeee;
  cursor: default !important;
}

.team-program-selector {
  background-color: white;
  background: white;
  border: 1px solid;
  border-color: var(--v-grey-base);
}
</style>
