<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <div class="d-flex flex-wrap">
          <v-autocomplete
            v-for="filter in filters"
            :key="filter.formQuestionId"
            :label="filter.label"
            :items="filter.FormQuestionValues || []"
            item-text="label"
            item-value="formQuestionValueId"
            v-model="selectedFilters[filter.formQuestionId]"
            outlined
            dense
            multiple
            clearable
            color="brandCyan"
            class="filter"
          ></v-autocomplete>
        </div>
        <div class="d-flex justify-end">
          <v-text-field
            label="Search"
            outlined
            dense
            color="brandCyan"
            class="mx-1"
            v-model="table.debounceSearch"
          ></v-text-field>
        </div>
        <v-data-table
          :key="1"
          :hide-default-header="hideDefaultHeader"
          :headers="headers"
          :items="visibleItems"
          :items-per-page="table.itemsPerPage"
          :footer-props="table.footerProps"
          :options.sync="table.options"
          :server-items-length="table.total"
          :show-select="false"
          :loading="table.loading"
          single-select
          loading-text="loading"
          must-sort
          sort-by="submitDate"
          sort-desc
          no-data-text="No submissions found"
          no-results-text="No submissions found"
          item-key="formSubmissionId"
          id="table"
          class="table"
          color="brandCyan"
          :item-class="table.rowClass"
          @click:row="rowClick"
        >
          <template v-slot:[`item.creatorName`]="{ item }">
            <span class="word-break">{{ item.creatorName }}</span
            ><br />
            <span class="word-break mediumGrey--text">{{
              item.creatorEmail || item.creatorPhone
            }}</span>
          </template>
          <template v-slot:[`item.submitDate`]="{ item }">
            <span class="word-break">{{ item.submitDate | formatDate }}</span>
          </template>
          <template v-slot:[`item.answers`]="{ item }">
            <v-btn
              icon
              @click="rowClick(item)"
              color="brandCyan"
              v-if="item?.FormSubmissionValues?.length"
              ><v-icon>mdi-arrow-right-circle</v-icon></v-btn
            ></template
          >
          <template v-slot:[`item.uploadFilePath`]="{ item }">
            <v-btn
              icon
              @click.stop.native="
                handleFileViewerClick(
                  item.formSubmissionId,
                  item.uploadFilePath
                )
              "
              v-if="item.uploadFilePath"
              ><v-icon>mdi-paperclip</v-icon></v-btn
            ></template
          >

          <template v-slot:loading>
            <!-- {{ item }} -->
            <v-progress-circular
              indeterminate
              color="primary"
              class="my-8"
              :size="50"
              :width="4"
            ></v-progress-circular>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog.fileViewer" :width="500">
      <v-card rounded="0" class="px-10 py-5">
        <FileViewer
          v-if="dialog.fileViewer"
          :formSubmissionId="dialog.fileViewerId"
          :filePath="dialog.fileViewerPath"
        />
        <v-card-actions class="pt-4">
          <v-spacer />
          <v-btn text color="brandCyan" @click="dialog.fileViewer = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
      v-model="dialog.answers"
      temporary
      fixed
      right
      width="500"
    >
      <v-toolbar dark color="brand" rounded="0" flat>
        <v-toolbar-title>Submitted answers</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="dialog.answers = false">
            Close
            <v-icon class="ml-2">mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <div class="pa-6" v-if="submission">
        <div class="d-flex justify-space-between align-start mb-4">
          <div class="d-flex flex-column text-left word-break ">
            <span class="font-weight-bold">{{
              submission.creatorName || ""
            }}</span>
            <span class="mt-2">{{
              submission.creatorEmail || submission.creatorPhone
            }}</span>
          </div>
          <div class="d-flex flex-column align-end text-left word-break ">
            <p class="font-weight-bold mb-0">
              {{ submission.submitDate | formatDate }}
            </p>
            <!-- <v-btn
              v-if="submission.uploadFilePath"
              icon
              color="brandCyan"
              @click="
                handleFileViewerClick(
                  submission.formSubmissionId,
                  submission.uploadFilePath
                )
              "
              ><v-icon>mdi-paperclip</v-icon></v-btn
            > -->
          </div>
        </div>
        <v-divider class="mb-4" />
        <h3 class="text-left mb-8">Submitted Answers:</h3>
        <div
          v-for="question in submission?.FormSubmissionValues || []"
          :key="question.formSubmissionValueId"
          class="d-flex flex-column align-start"
        >
          <span class="font-weight-bold">{{
            question.FormQuestion.label
          }}</span>
          <span class="mt-1" v-html="formatText(question.textValue)"></span>
          <div class="divider full-width my-3"></div>
        </div>
        <div v-if="submission?.uploadFilePath">
          <!-- <h3 class="text-left mb-8 mt-3">Uploaded File:</h3> -->
          <FileViewer
            class="mt-3"
            :formSubmissionId="submission?.formSubmissionId"
            :filePath="submission?.uploadFilePath"
          />
        </div>
      </div>
    </v-navigation-drawer>
    <v-overlay v-if="dialog.answers" />
  </div>
</template>

<script>
// Services
import FormService from "@/services/FormService";

// Components
import FileViewer from "@/components/forms/FileViewer";

// Data
import { debounce } from "@/shared_data/functions";
let debouncer;

// Packages
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "FormSubmissionTable",
  props: {
    formId: {
      type: Number
    },
    // search: {
    //   type: String
    // },
    sourcePage: {
      type: String,
      default: "spot-communications"
    },
    sourcePageProps: {
      type: Object,
      default: undefined
    },
    displayEmail: {
      type: Boolean,
      default: false
    },
    displayInfo: {
      type: Boolean,
      default: true
    },
    external: {
      type: Boolean,
      default: true
    },
    hideDefaultHeader: {
      type: Boolean,
      default: false
    },
    adminView: {
      type: Boolean,
      default: false
    }
  },
  components: { FileViewer },
  data() {
    return {
      loading: {
        assignments: false,
        analytics: [],
        filters: false
      },
      dialog: {
        preview: false,
        fileViewer: false,
        fileViewerId: null,
        fileViewerPath: null,
        answers: false
      },
      submission: null,
      item: null,
      filters: [],
      selectedFilters: {},
      table: {
        rowClass: () => "cursor-pointer",
        itemsPerPage: 10,
        page: 1,
        // Used if he user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
        awaitingPage: 1,
        search: null,
        debounceSearch: null,
        loading: false,
        items: [],
        total: 0,
        selected: [],
        options: {},
        footerProps: {
          "items-per-page-options": [10, 25, 50, 100]
        },
        latestFilter: null,
        latestSort: null,
        expanded: []
      }
    };
  },
  created() {
    debouncer = debounce();
  },
  mounted() {
    if (this.formId) this.getFormFilters(this.formId);
  },
  beforeDestroy() {},
  filters: {
    formatDate(v) {
      return moment(v).format("MMM DD, YYYY hh:mm a");
    }
  },
  methods: {
    async getItems(reset = false, source = null) {
      try {
        console.log("Getting items from ", source);
        console.log({ reset, source });
        this.table.loading = true;
        if (reset) this.table.options.page = 1;
        let options = this.table.options;
        let page = this.table.options.page || 1;
        let filter = `status = 'Active' && clientId = ${this.clientId}`;
        let limit = options.itemsPerPage;
        let offset = reset ? 0 : (page - 1) * limit;
        let sort = "formId DESC";
        if (this.table.options.sortBy && options.sortBy.length > 0) {
          let column = options.sortBy;
          let type =
            options.sortDesc &&
            options.sortDesc.length > 0 &&
            options.sortDesc[0] === true
              ? "DESC"
              : "ASC";

          sort = column + " " + type;
        }
        this.table.latestSort = sort;
        this.table.latestFilter = filter;
        let params = {
          filters: Object.entries(this.selectedFilters)
            ?.filter(x => x[1]?.length)
            ?.map(x => `${x[0]}::${x[1].join(",")}`),
          offset,
          limit,
          sort,
          clientId: this.clientId,
          search: this.table.search,
          includeValues: true
        };
        console.log(params);
        let response = await FormService.getFormSubmissionsV2(
          this.formId,
          params
        );

        // we have to compare states to see if we ignore results (only if them spam a filter)
        if (
          // filter != this.table.latestFilter ||
          this.table.search != params.search ||
          sort != this.table.latestSort ||
          page != this.table.options.page ||
          limit != this.table.options.itemsPerPage ||
          this.clientId != params.clientId
        ) {
          console.log("Ignoring API response");
          return;
        }

        console.log("Got programs ", response);
        this.table.total = response.result.count;
        let rows = response.result.rows.map(r => {
          if (r.startDate)
            r.startDate = moment(r.startDate).format("MMM D, YYYY h:mm a");
          if (r.endDate)
            r.endDate = moment(r.endDate).format("MMM D, YYYY h:mm a");
          return r;
        });

        this.table.items = rows;
        this.table.loading = false;
      } catch (err) {
        console.log("Error getting programs ", err);
        this.table.loading = false;
      }
    },
    rowClick(item) {
      let value = item?.item || item;
      console.log(value);

      if (!value?.FormSubmissionValues?.length) return;
      this.submission = value || {};
      this.submission.FormSubmissionValues = (
        value?.FormSubmissionValues || []
      )?.sort(
        (a, b) =>
          a?.FormQuestion?.questionIndex - b?.FormQuestion?.questionIndex
      );
      this.dialog.answers = true;
    },
    async getFormFilters(formId) {
      try {
        this.loading.filters = true;
        let response = await FormService.getFormFilterOptionsV2(formId);
        console.log("Got form filters ", response);
        this.filters = response?.result?.rows || [];
      } catch (err) {
        console.log("Error getting form filters ", err);
      } finally {
        this.loading.filters = false;
      }
    },
    handleFileViewerClick(submissionId, url) {
      this.dialog.fileViewer = true;
      this.dialog.fileViewerId = submissionId;
      this.dialog.fileViewerPath = url;
    },
    formatText(v) {
      return v?.replace(/\n/g, "<br />");
    }
  },
  computed: {
    ...mapState(["userProfile", "globalClientId"]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    visibleItems() {
      if (this.table.loading) return [];
      return this.table.items || [];
    },
    headers() {
      return [
        {
          text: "Submitter",
          align: "start",
          sortable: true,
          value: "creatorName",
          width: "25%"
        },
        {
          text: "Date",
          // align: "center",
          sortable: true,
          value: "submitDate",
          width: "25%"
        },
        {
          text: "File",
          align: "center",
          sortable: true,
          value: "uploadFilePath",
          width: "25%"
        },
        {
          text: "Answers",
          value: "answers",
          align: "end",
          width: "10%",
          sortable: false
        }
      ].filter(Boolean);
    }
  },
  watch: {
    "table.options": {
      handler(newVal, oldVal) {
        console.log("New options ", newVal);
        console.log("Old options ", oldVal);
        // Used to indicate if we wipe the table and start with an empty array, versus pushing more data on
        var reset = false;
        var apiCall = true;
        // If none of these exist then we're probably loading the page for the first time so we reset
        if (
          !oldVal.itemsPerPage ||
          !oldVal.page ||
          !oldVal.sortBy ||
          !oldVal.sortDesc
        )
          reset = true;
        else {
          // If any values changed from their old to new states, then we reset because they're changing a sort
          if (
            newVal.itemsPerPage !== oldVal.itemsPerPage ||
            (newVal.sortBy &&
              oldVal.sortBy &&
              newVal.sortBy[0] !== oldVal.sortBy[0]) ||
            (newVal.sortDesc &&
              oldVal.sortDesc &&
              newVal.sortDesc[0] !== oldVal.sortDesc[0])
          )
            reset = true;
        }

        if (apiCall) this.getItems(reset, "watcher");
      },
      deep: true
    },
    clientId: function(newVal, oldVal) {
      // If client id changes, we get budgets!
      if (newVal != oldVal && oldVal != null) {
        this.getItems(true, "client watcher");
      }
    },
    // search: function() {
    //   this.getItems(true, "search watcher");
    // },
    "table.debounceSearch": debounce(function(newVal) {
      this.table.search = newVal;
      this.getItems(true, "search watcher");
    }, 500),
    selectedFilters: {
      handler: debounce(function() {
        this.getItems(true, "filters watcher");
      }, 500),
      deep: true
    },
    "dialog.answers": function(newVal) {
      if (!newVal) this.submission = null;
    }
  }
};
</script>

<style scoped>
/* Removes box shadow from table expansion */
#table >>> .v-data-table__expanded__content {
  box-shadow: none;
}

/* Hover and background for expanded buttons */
.preview-button {
  cursor: pointer;
  padding: 4px 6px 4px 6px;
  border-radius: 4px;
}
.preview-button:hover {
  background-color: #eee;
}

/* Caps width of page view bar */
.page-views-bar {
  min-width: 225px;
  max-width: 330px;
}

.form-submission-button {
  cursor: pointer;
  padding: 8px 8px 8px 8px;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
  user-select: none;
}
.form-submission-button:hover {
  background-color: rgba(237, 237, 237, 0.7);
}

/* Divider because v-divider doesn't appear when flex-direction is column */
.divider {
  border: 0.5px solid lightgray;
}

.filter {
  width: 40%;
  max-width: 400px;
  min-width: 200px;
  margin-left: 4px;
  margin-right: 4px;
}
</style>
